// Imports => React
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Fade } from 'react-reveal';

// Imports => Constants
import { ICONS } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Controller
import AcTextInputController from './ac-text-input.controller';

// Imports => Atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcStrengthIndicator from '@atoms/ac-strength-indicator/ac-strength-indicator.web';

// Component
class AcTextInput extends AcTextInputController {
  buildError() {
    const { t } = this.props;
    const { hasError } = this.state;
    return (
      <Fade duration={250}>
        <div className={this.getErrorClassNames()}>{t(hasError)}</div>
      </Fade>
    );
  }

  buildInstructions() {
    const { instructions, t } = this.props;
    return (
      <div className={this.getInstructionsClassNames()}>{t(instructions)}</div>
    );
  }

  buildTextInput() {
    const { value, disabled, readonly, autocomplete } = this.props;
    const { name, reference, type } = this.state;

    const tabindex = AcIsSet(this.props.tabindex)
      ? this.props.tabindex
      : AcIsSet(this.state.tabindex)
      ? this.state.tabindex
      : 0;

    let pattern = null;
    if (type === 'number') pattern = '[-+]?[0-9]*[.,]?[0-9]+';

    let _props = {};
    if (pattern) {
      _props = { pattern };
    }

    if (type === 'textarea') {
      return (
        <textarea
          type={type}
          name={name}
          id={reference}
          value={value}
          placeholder={this.getPlaceholder()}
          className={this.getInputClassNames()}
          disabled={disabled}
          readOnly={readonly}
          autoComplete={autocomplete ? 'on' : 'off'}
          tabIndex={tabindex}
          onChange={this.onChange}
          onKeyUp={this.onKeyUp}
          ref={this.element}
          {..._props}
        />
      );
    }

    return (
      <input
        type={type}
        name={name}
        id={reference}
        value={value}
        placeholder={this.getPlaceholder()}
        className={this.getInputClassNames()}
        disabled={disabled}
        readOnly={readonly}
        pattern={pattern}
        autoComplete={autocomplete ? 'on' : 'off'}
        tabIndex={tabindex}
        onChange={this.onChange}
        onKeyUp={this.onKeyUp}
        ref={this.element}
        {..._props}
      />
    );
  }

  render() {
    const {
      label,
      type,
      value,
      disabled,
      readonly,
      instructions,
      indicator,
      eye,
      t,
    } = this.props;
    const { hasError, eyeOpen, reference } = this.state;

    return (
      <div
        ref={node => (this.el = node)}
        aria-roledescription={'input'}
        aria-labelledby={`label-${reference}`}
        className={this.getStyleClassNames()}
        disabled={disabled}
        readOnly={readonly}
      >
        {this.buildTextInput()}
        {label && (
          <label
            id={`label-${reference}`}
            htmlFor={reference}
            className={this.getLabelClassNames()}
          >
            {t(label)}
          </label>
        )}
        {hasError && this.buildError()}
        {!hasError && instructions && this.buildInstructions()}
        {type === 'password' && indicator && (
          <AcStrengthIndicator value={value} />
        )}
        {type === 'password' && eye && (
          <span
            className={this.getEyeClassNames()}
            onClick={this.handleEyeClick}
          >
            <AcIcon
              icon={eyeOpen ? ICONS.EYE_OFF_OUTLINE : ICONS.EYE_OUTLINE}
            />
          </span>
        )}
      </div>
    );
  }
}

export default withNamespaces()(AcTextInput);
