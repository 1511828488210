// Imports => Constants
import { ENDPOINTS } from '@constants';

export class CompaniesAPI {
	constructor(Instance) {
		this.Client = Instance.Client;
		this.Store = Instance.Store;
	}

	get = () => {
		return this.Client.get(
			ENDPOINTS(this.Store.auth.current_role).COMPANIES.ALL
		).then(response => response.data);
	};

	get_by_id = _order_id => {
		return this.Client.get(
			ENDPOINTS(this.Store.auth.current_role).COMPANIES.GET_BY_ID(_order_id)
		).then(response => response.data);
	};
}

export default CompaniesAPI;
