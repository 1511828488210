// Imports => MOBX
import { observable, computed, action, toJS } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Models
import { DeliverySlot } from '@models/delivery-slot.model';

// Imports => Utilities
import {
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
} from '@utils';

let app = {};
let timeout = null;

export class SlotsStore {
	constructor(store) {
		// AcAutoLoad(this, KEYS.DELIVERY_SLOTS);
		// AcAutoLoad(this, KEYS.SLOTS);
		AcAutoLoad(this, KEYS.SLOT);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	delivery_slots = [];

	@observable
	holidays = [];

	@observable
	slots = [];

	@observable
	slot = [];

	@observable
	loading = {
		status: false,
		message: null,
	};

	@computed
	get available_delivery_slots() {
		return toJS(this.delivery_slots);
	}

	@computed
	get available_slots() {
		return toJS(this.slots);
	}

	@action
	setLoading(state) {
		this.loading = {
			status: state || false,
			message: 'Cleaning your delivery windows',
		};
	}

	@action
	get_holidays() {
		return app.store.api.delivery_slots
			.get_holidays()
			.then(response => {
				this.set(KEYS.HOLIDAYS, response.data);

				return response;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: 'Failed to retreive holidays',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				throw error;
			});
	}

	@action
	get_by_location_id = async (_location_id, _finish) => {
		if (!app.store.auth.is_authorized) return;

		this.setLoading(true);

		await this.get_holidays()
			.then(async () => {
				return await this.get_slots(_location_id, _finish);
			})
			.catch(async () => {
				return await this.get_slots(_location_id, _finish);
			});
	};

	@action
	get_slots = (_location_id, _finish) => {
		return app.store.api.delivery_slots
			.get_by_location_id(_location_id)
			.then(async response => {
				this.set(KEYS.DELIVERY_SLOTS, response.data);

				if (_finish) this.setLoading(false);
				return response;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: 'Failed to retreive your delivery windows',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				if (_finish) this.setLoading(false);
				throw error;
			});
	};

	@action
	get_by_id(_id) {
		this.setLoading(true);
		return app.store.api.delivery_slots
			.get_by_id(_id)
			.then(response => {
				this.set(KEYS.SLOT, response.data);

				this.setLoading(false);
				return response;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: 'Failed to retreive your delivery window',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	}

	@action
	async parseData() {
		return new Promise(async resolve => {
			if (timeout) clearTimeout(timeout);
			this.setLoading(true);

			const { locations } = app.store.locations;
			const delivery_slots = this.available_delivery_slots;
			const holidays = this.holidays;

			this.slots = await new DeliverySlot(delivery_slots, locations, holidays);
			AcSaveState(KEYS.SLOTS, this.slots, true);

			timeout = setTimeout(() => {
				resolve();
				this.setLoading(false);
			}, 1000);
		});
	}

	@action
	toggle(target) {
		const slots = this.slots;
		let result = null;

		this.slots = slots.map(item => {
			const slot = {
				...item,
				expanded: target && target.id === item.id && !item.expanded,
			};
			if (target && target.id === item.id) result = slot;

			return slot;
		});

		return result;
	}

	@action
	collapse() {
		const slots = this.slots;

		this.slots = slots.map(item => ({
			...item,
			expanded: false,
		}));
	}

	@action
	set(target, value, save = true) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;
		if (typeof value === 'undefined' || value === null) return;

		this[target] = value;
		if (save) AcSaveState(target, this[target]);
	}
}

export default SlotsStore;
