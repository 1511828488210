// Imports => Constants
import { ENDPOINTS } from '@constants';

export class AnnouncementsAPI {
	constructor(Instance) {
		this.Client = Instance.Client;
		this.Store = Instance.Store;
	}

	get = data => {
		return this.Client.get(ENDPOINTS().ANNOUNCEMENTS.ALL).then(
			response => response.data
		);
	};
}

export default AnnouncementsAPI;
