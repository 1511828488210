// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
	MAIN: 'ac-sticky-bar',
	POSITION: {
		TOP: 'ac-sticky-bar--top',
		BOTTOM: 'ac-sticky-bar--bottom',
		RIGHT: 'ac-sticky-bar--right',
		LEFT: 'ac-sticky-bar--left',
	},
};

// Component
const AcStickyBar = ({ className, position, children }) => {
	const getStyleClassNames = () => {
		return clsx(
			_CLASSES.MAIN,
			position && _CLASSES.POSITION[position.toUpperCase()],
			className
		);
	};

	return children ? (
		<div className={getStyleClassNames()}>{children}</div>
	) : null;
};

export default React.memo(AcStickyBar);
