export const Translations = [
	{
		nl: 'Aan het laden...',
		en: 'Loading...',
	},
	{
		nl: 'Even geduld...',
		en: 'One moment please...',
	},
	{
		nl: 'Een moment geduld...',
		en: 'Thanks for your patience...',
	},
	{
		nl: 'Terug naar inloggen',
		en: 'Return to login',
	},
	{
		nl: 'Toetsenbord sneltoetsen',
		en: 'Keyboard shortcuts',
	},
	{
		nl: 'Herhaalopdrachten',
		en: 'Recurring orders',
	},
	{
		nl: 'Nieuwe herhaalopdracht',
		en: 'Add recurring order',
	},
	{
		nl: 'Nieuwe herhaalopdracht',
		en: 'New recurring order',
	},
	{
		nl: 'herhaalopdracht',
		en: 'recurring order',
	},
	{
		nl: 'Weet je zeker dat je {{entity}} {{name}} wilt verwijderen?',
		en: 'Are you sure you want to delete {{entity}} {{name}}?',
	},
	{
		nl: 'van',
		en: 'from',
	},
	{
		nl: 'Versturen',
		en: 'Send',
	},
	{
		nl: 'Toevoegen',
		en: 'Add',
	},
	{
		nl: 'Klik om een startdatum te kiezen',
		en: 'Click to select a start date',
	},
	{
		nl: 'Klik om een einddatum te kiezen',
		en: 'Click to select an end date',
	},
	{
		nl: 'Van',
		en: 'From',
	},
	{
		nl: 'tot',
		en: 'to',
	},
	{
		nl: 'Tot',
		en: 'To',
	},
	{
		nl: 'Voeg nog een sealbag toe',
		en: 'Add another sealbag',
	},
	{
		nl: 'Filteren',
		en: 'Filter',
	},
	{
		nl: 'Filter op besteldatum',
		en: 'Filter on order date',
	},
	{
		nl: 'Filter op transportdatum',
		en: 'Filter on delivery date',
	},
	{
		nl: 'Gebruiker {{name}} is gewijzigd',
		en: 'User {{name}} is updated',
	},
	{
		nl: 'Wijzigen van gebruiker {{name}} is niet gelukt',
		en: 'Failed to update user {{name}}',
	},
	{
		nl: 'Geen resultaten gevonden...',
		en: 'No results found...',
	},
	{
		nl: 'Bekijk details',
		en: 'View details',
	},
	{
		nl: 'Bestelling details',
		en: 'Order Details',
	},
	{
		nl: 'Onderwerp',
		en: 'Subject',
	},
	{
		nl: 'Bericht',
		en: 'Message',
	},
	{
		nl: 'Contactformulier',
		en: 'Contact form',
	},
	{
		nl: 'Bevestig je wachtwoord',
		en: 'Confirm your password',
	},
	{
		nl: 'Company Admin',
		en: 'Company Admin',
	},
	{
		nl: 'Deze actie is niet toegestaan.',
		en: 'This action is unauthorized.',
	},
	{
		nl: 'Dhr.',
		en: 'Mr.',
	},
	{
		nl: 'Dit is een verplicht veld',
		en: 'This field is required',
	},
	{
		nl: 'E-mailadres',
		en: 'Emailaddress',
	},
	{
		nl: 'Er zijn geen gekoppelde locaties gevonden of beschikbaar',
		en: 'No locations available',
	},
	{
		nl: 'Gebruiker opslaan',
		en: 'Save user',
	},
	{
		nl: 'Gebruiker toevoegen',
		en: 'Add user',
	},
	{
		nl: 'Gebruiker verwijderen',
		en: 'Delete user',
	},
	{
		nl: 'Opdracht verwijderen',
		en: 'Delete order',
	},
	{
		nl: 'Gebruiker wijzigen',
		en: 'Update user',
	},
	{
		nl: 'Gebruiker wordt verwijderd',
		en: 'Deleting user',
	},
	{
		nl: 'Gebruiker {{name}} is verwijderd',
		en: 'Successfully deleted user {{name}}',
	},
	{
		nl: 'Geen leveropdrachten gevonden',
		en: 'No Delivery Orders found',
	},
	{
		nl: 'Geen ophaalopdrachten gevonden',
		en: 'No Pick-up Orders found',
	},
	{
		nl: 'Download specificatie',
		en: 'Download specification',
	},
	{
		nl: 'Download specificaties',
		en: 'Download specifications',
	},
	{
		nl: 'Bedankt voor uw opdracht.',
		en: 'Thank you for your order.',
	},
	{
		nl: 'Download de ophaal specificaties en voeg deze toe aan de juiste sealbags.',
		en:
			'Download the pick-up specification(s) and include it in the corresponding sealbags.',
	},
	{
		nl:
			'Download en print uw specificaties en voeg deze toe aan de juiste sealbags.',
		en:
			'Download and print your specification(s) and add it to the corresponding sealbags.',
	},
	{
		nl: 'Download en print uw specificatie en voeg deze toe aan de sealbag.',
		en: 'Download and print your specification and add it to the sealbag.',
	},
	{
		nl: 'Geld ophalen',
		en: 'Pick-up Cash',
	},
	{
		nl: 'Naar inloggen',
		en: 'To login',
	},
	{
		nl: 'Toevoegen van gebruiker {{name}} is niet gelukt',
		en: 'Failed to add user {{name}}',
	},
	{
		nl: 'Het is niet gelukt om gebruiker {{name}} te verwijderen',
		en: 'Failed to delete user {{name}}',
	},
	{
		nl: 'Het ophalen van locatiegegevens is niet gelukt',
		en: 'Failed to retreive location data',
	},
	{
		nl: 'Het plaatsen van uw opdracht is niet gelukt',
		en: 'Failed to store your order',
	},
	{
		nl: 'Nieuw wachtwoord is opgeslagen',
		en: 'Saved new password',
	},
	{
		nl: 'In opdracht van',
		en: 'Commissioned by',
	},
	{
		nl: 'Ja, verwijder',
		en: 'Yes, delete',
	},
	{
		nl: 'Kies een locatie',
		en: 'Choose a location',
	},
	{
		nl: 'Kies een gebruikersrol',
		en: 'Choose a role',
	},
	{
		nl: 'Koopzegels bestellen',
		en: 'Order Stamps',
	},
	{
		nl: 'Startdatum',
		en: 'Start date',
	},
	{
		nl: 'Einddatum',
		en: 'End date',
	},
	{
		nl: 'Maximum voor dit product is',
		en: 'Maximum number of items allowed is',
	},
	{
		nl: 'De gebruikersgegevens zijn onjuist.',
		en: 'The user credentials were incorrect.',
	},
	{
		nl: '<p>De gebruikersgegevens zijn onjuist.</p>',
		en: '<p>The user credentials were incorrect.</p>',
	},
	{
		nl: 'Mevr.',
		en: 'Mrs.',
	},
	{
		nl: 'Mijn account',
		en: 'My Account',
	},
	{
		nl: 'Mijn bedrijf',
		en: 'My Company',
	},
	{
		nl: 'Mijn Details',
		en: 'My Details',
	},
	{
		nl: 'Mijn Locaties',
		en: 'My Locations',
	},
	{
		nl: 'Minimum voor dit product is',
		en: 'Minimum number of items allowed is',
	},
	{
		nl: 'Niet meer mogelijk',
		en: 'Not available',
	},
	{
		nl: 'Nieuwe bestelling',
		en: 'New order',
	},
	{
		nl: 'Nieuwe gebruiker is toegevoegd',
		en: 'New user has been added',
	},
	{
		nl: 'Opdracht aan het plaatsen',
		en: 'Storing your order',
	},
	{
		nl: 'Ophalen & Leveren',
		en: 'Pick-up & Deliver',
	},
	{
		nl: 'Ordernr.',
		en: 'Orderno.',
	},
	{
		nl: 'Opdracht plaatsen',
		en: 'Submit order',
	},
	{
		nl: 'Sealbags bestellen',
		en: 'Order Sealbags',
	},
	{
		nl: 'Toevoegen van de nieuwe gebruiker is niet gelukt',
		en: 'Failed to add the new user',
	},
	{
		nl: 'Transport Schema',
		en: 'Transport Schedule',
	},
	{
		nl: 'Uew bestelling',
		en: 'Your order',
	},
	{
		nl: 'Uw leveropdracht',
		en: 'Your delivery order',
	},
	{
		nl: 'Uw leveropdrachten',
		en: 'Your delivery orders',
	},
	{
		nl: 'Uw ophaalopdracht',
		en: 'Your pick-up order',
	},
	{
		nl: 'Uw ophaalopdrachten',
		en: 'Your pick-up orders',
	},
	{
		nl: 'Verstuur uitnodiging na toevoegen',
		en: 'Send invitation after creation',
	},
	{
		nl: 'Verstuur uitnodiging',
		en: 'Send invitation',
	},
	{
		nl: 'Voeg nog een sealbag toe',
		en: 'Add another sealbag',
	},
	{
		nl: 'Voer je huidige en nieuwe wachtwoord in.',
		en: 'Enter your current password and your new password.',
	},
	{
		nl: 'Voer je huidige wachtwoord in om de wijzigingen te bevestigen',
		en: 'Enter your current password to confirm your changes',
	},
	{
		nl: 'Wachtwoord wijzigen',
		en: 'Change password',
	},
	{
		nl: 'Weet je zeker dat je gebruiker {{name}} wilt verwijderen?',
		en: 'Are you sure you want to delete user {{name}}?',
	},
	{
		nl: 'Weet je zeker dat je opdracht {{name}} wilt verwijderen?',
		en: 'Are you sure you want to delete order {{name}}?',
	},
	{
		nl: 'Wijzigingen opslaan',
		en: 'Save changes',
	},
	{
		nl: 'Wisselgeld bestellen',
		en: 'Order Change',
	},
	{
		nl: 'Zoek een bestelling...',
		en: 'Find an order...',
	},
	{
		nl: 'Zoek een gebruiker...',
		en: 'Find an user...',
	},
	{
		nl: '{{min}} - {{max}} van {{total}} resultaten',
		en: '{{min}} - {{max}} of {{total}} results',
	},
	{
		nl: 'Aanpassen',
		en: 'Edit',
	},
	{
		nl: 'Achternaam',
		en: 'Last name',
	},
	{
		nl: 'Actief',
		en: 'Active',
	},
	{
		nl: 'Activeren',
		en: 'Activate',
	},
	{
		nl: 'Admin',
		en: 'Admin',
	},
	{
		nl: 'Adres',
		en: 'Address',
	},
	{
		nl: 'Annuleren',
		en: 'Cancel',
	},
	{
		nl: 'Bedrijf',
		en: 'Company',
	},
	{
		nl: 'Besteldatum',
		en: 'Order date',
	},
	{
		nl: 'Transportdatum',
		en: 'Delivery date',
	},
	{
		nl: 'Bestelling',
		en: 'Order',
	},
	{
		nl: 'bestelling',
		en: 'order',
	},
	{
		nl: 'Bestellingen',
		en: 'Orders',
	},
	{
		nl: 'bestellingen',
		en: 'orders',
	},
	{
		nl: 'Besteloverzicht',
		en: 'Order Overview',
	},
	{
		nl: 'Bevestigen',
		en: 'Confirm',
	},
	{
		nl: 'Biljetten',
		en: 'Notes',
	},
	{
		nl: 'biljetten',
		en: 'notes',
	},
	{
		nl: 'Deactiveren',
		en: 'Deactivate',
	},
	{
		nl: 'Details',
		en: 'Details',
	},
	{
		nl: 'Foliepakken',
		en: 'Foil bags',
	},
	{
		nl: 'Gebruiker',
		en: 'User',
	},
	{
		nl: 'gebruiker',
		en: 'user',
	},
	{
		nl: 'Gebruikers',
		en: 'Users',
	},
	{
		nl: 'gebruikers',
		en: 'users',
	},
	{
		nl: 'Gebruikersnaam',
		en: 'Username',
	},
	{
		nl: 'Terug naar inloggen',
		en: 'Back to login',
	},
	{
		nl: 'Inloggen',
		en: 'Login',
	},
	{
		nl: 'Inloggen is niet gelukt',
		en: 'Login failed',
	},
	{
		nl: 'Wachtwoord vergeten?',
		en: 'Forgot your password?',
	},
	{
		nl: 'Wachtwoord resetten',
		en: 'Reset your password',
	},
	{
		nl: 'Wachtwoord resetten',
		en: 'Reset password',
	},
	{
		nl: 'Verstuur nieuwe uitnodiging',
		en: 'Resend invitation',
	},
	{
		nl: 'Verstuur uitnodiging',
		en: 'Send invitation',
	},
	{
		nl: 'Gebruikersoverzicht',
		en: 'User Overview',
	},
	{
		nl: 'Inactief',
		en: 'Inactive',
	},
	{
		nl: 'Onvoldoende rechten voor deze actie.',
		en: 'Unauthenticated.',
	},
	{
		nl: 'Onvoldoende rechten voor deze actie',
		en: 'Unauthenticated',
	},
	{
		nl: 'Geen toestemming voor deze actie',
		en: 'Unauthorized',
	},
	{
		nl: 'Geen toestemming voor deze actie.',
		en: 'Unauthorized.',
	},
	{
		nl: 'Koopzegels',
		en: 'Stamps',
	},
	{
		nl: 'Leveren',
		en: 'Deliver',
	},
	{
		nl: 'leveren',
		en: 'deliver',
	},
	{
		nl: 'Leveropdracht',
		en: 'Delivery order',
	},
	{
		nl: 'Leveropdrachten',
		en: 'Delivery orders',
	},
	{
		nl: 'Locatie',
		en: 'Location',
	},
	{
		nl: 'Locatiecode',
		en: 'Locationcode',
	},
	{
		nl: 'Locatie {{code}} - {{name}} - {{reference}}',
		en: 'Location {{code}} - {{name}} - {{reference}}',
	},
	{
		nl: 'Klantreferentie',
		en: 'Internal reference',
	},
	{
		nl: 'klantreferentie',
		en: 'internal reference',
	},
	{
		nl: 'Manager',
		en: 'Manager',
	},
	{
		nl: 'Filiaalmanager',
		en: 'Branch Manager',
	},
	{
		nl: 'Munten',
		en: 'Coins',
	},
	{
		nl: 'munten',
		en: 'coins',
	},
	{
		nl: 'Muntrollen',
		en: 'Coin rolls',
	},
	{
		nl: 'Wachtwoord opslaan',
		en: 'Save password',
	},
	{
		nl: 'Muntzakken',
		en: 'Coin bags',
	},
	{
		nl: 'Naam',
		en: 'Name',
	},
	{
		nl: 'Ophaalopdracht',
		en: 'Pick-up order',
	},
	{
		nl: 'Ophaalopdrachten',
		en: 'Pick-up orders',
	},
	{
		nl: 'Ophalen',
		en: 'Pick-up',
	},
	{
		nl: 'ophalen',
		en: 'pick-up',
	},
	{
		nl: 'Opslaan',
		en: 'Save',
	},
	{
		nl: 'resterend',
		en: 'remaining',
	},
	{
		nl: 'Rol',
		en: 'Role',
	},
	{
		nl: 'Functie',
		en: 'Function',
	},
	{
		nl: 'Sealbagnummer',
		en: 'Sealbagnumber',
	},
	{
		nl: 'Sealbags',
		en: 'Sealbags',
	},
	{
		nl: 'stuks',
		en: 'items',
	},
	{
		nl: 'Totaal',
		en: 'Total',
	},
	{
		nl: 'totaal',
		en: 'total',
	},
	{
		nl: 'Totaal gewicht',
		en: 'Total weight',
	},
	{
		nl: 'totaal gewicht',
		en: 'total weight',
	},
	{
		nl: 'Transport',
		en: 'Transport',
	},
	{
		nl: 'Transportdatum',
		en: 'Transport date',
	},
	{
		nl: 'Tussenvoegsel',
		en: 'Middle name',
	},
	{
		nl: 'Types',
		en: 'Types',
	},
	{
		nl: 'Filter op',
		en: 'Filter on',
	},
	{
		nl: 'Uitgenodigd',
		en: 'Pending',
	},
	{
		nl: 'vandaag',
		en: 'today',
	},
	{
		nl: 'Verwijderen',
		en: 'Delete',
	},
	{
		nl: 'Voornaam',
		en: 'First name',
	},
	{
		nl: 'Wachtwoord',
		en: 'Password',
	},
	{
		nl: 'Wisselgeld',
		en: 'Change',
	},
	{
		nl: 'De opdracht is geplaatst',
		en: 'Your order is stored',
	},
	{
		nl: 'Wachtwoord wijzigen is niet gelukt',
		en: 'Failed to update password',
	},
	{
		nl: 'Wachtwoord is gewijzigd',
		en: 'Password is updated',
	},
	{
		nl: 'Nieuw wachtwoord',
		en: 'New password',
	},
	{
		nl: 'Herhaal nieuw wachtwoord',
		en: 'Repeat new password',
	},
	{
		nl: 'Wachtwoord is een verplicht veld',
		en: 'Password is a required field',
	},
	{
		nl: 'Nieuw wachtwoord is een verplicht veld',
		en: 'New password is a required field',
	},
	{
		nl: 'Herhaal nieuw wachtwoord is een verplicht veld',
		en: 'Repeat new password is a required field',
	},
	{
		nl: 'Herhaal nieuw wachtwoord en Nieuw wachtwoord komen niet overeen',
		en: 'Repeat new password must be the same as New password',
	},
	{
		nl:
			'<p>Uw wachtwoord is opgeslagen. U kunt nu inloggen met het nieuwe wachtwoord.</p>',
		en:
			'<p>Your new password is saved. You can proceed to login using your new password.</p>',
	},
	{
		nl:
			'<p>Het wachtwoord moet uit minimaal 8 tekens bestaan en uit letters en cijfers bestaan. Voeg speciale tekens toe om het nog veiliger te maken!</p>',
		en:
			'<p>The password must consist of a minimum of 8 characters and letters and numbers. Add special characters to make it even safer!</p>',
	},
	{
		nl: 'E-mail is verzonden',
		en: 'Email has been sent',
	},
	{
		nl: 'Verifiëren van authenticatiegegevens',
		en: 'Verifying authentication credentials',
	},
	{
		nl: 'Nieuw wachtwoord is aangevraagd',
		en: 'Successfully requested a new password',
	},
	{
		nl: 'Aanvragen van nieuw wachtwoord is niet gelukt',
		en: 'Failed to request a new password',
	},
	{
		nl: 'Nieuw wachtwoord is opgeslagen',
		en: 'New password is stored',
	},
	{
		nl: 'Nieuw wachtwoord opslaan is niet gelukt',
		en: 'Failed to store your new password',
	},
	{
		nl: 'Bedrijven aan het ophalen',
		en: 'Gathering companies',
	},
	{
		nl: 'Locaties in kaart aan het brengen',
		en: 'Mapping your locations',
	},
	{
		nl: 'Kluis wordt geopend',
		en: 'Opening the vault',
	},
	{
		nl: 'Bestelling wordt opgeslagen',
		en: 'Storing your order',
	},
	{
		nl: 'Bestelling is opgeslagen',
		en: 'Your order is stored',
	},
	{
		nl: 'Opslaan van de bestelling is niet gelukt',
		en: 'Failed to store your order',
	},
	{
		nl: 'Bestellingen aan het sorteren',
		en: 'Sorting your orders',
	},
	{
		nl: 'Profiel aan het controleren',
		en: 'Checking your profile',
	},
	{
		nl: 'Ophalen van je profiel is niet gelukt',
		en: 'Failed to retreive your profile',
	},
	{
		nl: 'Je profiel is gewijzigd',
		en: 'Your profile is updated',
	},
	{
		nl: 'Wijzigen van je profiel is niet gelukt',
		en: 'Failed to update your profile',
	},
	{
		nl: 'Je wachtwoord is gewijzigd',
		en: 'Your password is updated',
	},
	{
		nl: 'Wijzigen van je wachtwoord is niet gelukt',
		en: 'Failed to update your password',
	},
	{
		nl: 'Bezorgtijden aan het opschonen',
		en: 'Cleaning your delivery windows',
	},
	{
		nl: 'Ophalen van je bezorgtijden is niet gelukt',
		en: 'Failed to retreive your delivery windows',
	},
	{
		nl: 'Ophalen van de bezorgtijd is niet gelukt',
		en: 'Failed to retreive the delivery window',
	},
	{
		nl: 'Gebruikers aan het verzamelen',
		en: 'Gathering users',
	},
	{
		nl: 'Ophalen van de gebruikers is niet gelukt',
		en: 'Failed to load users',
	},
	{
		nl: 'Ophalen van de gebruiker is niet gelukt',
		en: 'Failed to load user',
	},
	{
		nl: 'Nieuwe gebruiker aan het opslaan',
		en: 'Storing the new user',
	},
	{
		nl: 'Opslaan van de nieuwe gebruiker is niet gelukt',
		en: 'Failed to store the new user',
	},
	{
		nl: 'Uitnodiging aan het opstellen',
		en: 'Preparing the invitation',
	},
	{
		nl: 'Uitnodiging verstuurd naar gebruiker {{name}}',
		en: 'Successfully (re)send an invitation to user {{name}}',
	},
	{
		nl: 'Versturen van een uitnodiging naar gebruiker {{name}} is niet gelukt',
		en: 'Failed to (re)send an invitation to user {{name}}',
	},
];

export default Translations;
