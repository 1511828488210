// Imports => MOBX
import { observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
} from '@utils';

const _default = {
	options: {},
	profile: {
		id: '',
		name: '',
		role: '',
		email: '',
		company_id: '',
		location_id: '',
		active: '',
	},
	profile_clone: {
		id: '',
		name: '',
		role: '',
		email: '',
		company_id: '',
		location_id: '',
		active: '',
		password: '',
		new_password: '',
		new_password_confirmation: '',
	},
};

let app = {};

export class ProfileStore {
	constructor(store) {
		AcAutoLoad(this, KEYS.PROFILE);
		AcAutoLoad(this, KEYS.PROFILE_CLONE);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	profile = {};

	@observable
	profile_clone = _default.profile_clone;

	@computed
	get current_profile() {
		return this.profile;
	}

	@computed
	get current_profile_clone() {
		return this.profile_clone;
	}

	@computed
	get current_permissions() {
		if (!this.current_profile || !this.current_profile.permissions) return [];
		return this.current_profile && this.current_profile.permissions.map(n => n.name);
	}

	@observable
	loading = {
		status: false,
		message: null,
	};

	@action
	setLoading(state, message) {
		this.loading = {
			status: state || false,
			message: message || 'Checking your profile',
		};
	}

	@action
	who_am_i = () => {
		this.setLoading(true);

		return app.store.api.profile
			.whoami()
			.then(response => {
				const _data = {
					...response.data,
					password: '',
					new_password: '',
					new_password_confirmation: '',
				};
				this.set(KEYS.PROFILE, response.data, true);
				this.set(KEYS.PROFILE_CLONE, _data);

				this.setLoading(false);
				return response;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: 'Failed to retreive your profile',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	@action
	update = () => {
		this.setLoading(true, false);

		const clone = this.profile_clone;
		const data = {
			name: clone.name,
			email: clone.email,
			password: clone.password,
			// new_password: clone.new_password,
			// new_password_confirmation: clone.new_password_confirmation,
		};

		return app.store.api.profile
			.update(data)
			.then(response => {
				const _data = {
					...response.data,
					password: '',
					new_password: '',
					new_password_confirmation: '',
				};
				this.set(KEYS.PROFILE, response.data, true);
				this.set(KEYS.PROFILE_CLONE, _data);

				app.store.toasters.add({
					variant: 'success',
					title: 'Your profile is updated',
				});

				this.setLoading(false);
				return response;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: 'Failed to update your profile',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.who_am_i();

				throw error;
			});
	};

	@action
	change_password = () => {
		this.setLoading(true, false);

		const clone = this.profile_clone;
		const data = {
			name: clone.name,
			email: clone.email,
			password: clone.password,
			new_password: clone.new_password,
			new_password_confirmation: clone.new_password_confirmation,
		};

		return app.store.api.profile
			.update(data)
			.then(response => {
				const _data = {
					...response.data,
					password: '',
					new_password: '',
					new_password_confirmation: '',
				};
				this.set(KEYS.PROFILE, response.data, true);
				this.set(KEYS.PROFILE_CLONE, _data);

				app.store.toasters.add({
					variant: 'success',
					title: 'Your password is updated',
				});

				app.store.ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
				this.setLoading(false);

				return response;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: 'Failed to update your password',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				app.store.ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);

				this.who_am_i();

				throw error;
			});
	};

	@action
	set(target, value, store) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;
		if (typeof value === 'undefined' || value === null) return;

		this[target] = value;

		if (store) {
			AcSaveState(target, this[target]);
		}
	}

	@action
	setValue(target, property, value) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;
		if (!property) return;
		if (typeof value === 'undefined' || value === null) return;

		this[target][property] = value;
		AcSaveState(target, this[target]);
	}

	@action
	reset(target) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;

		this[target] = _default[target];
		AcSaveState(target, this[target]);
	}
}

export default ProfileStore;
