const ANNOUNCEMENTS = '/announcements';
const API = '/api';
const ATTACHMENTS = '/attachments';
const COINS = '/coins';
const COMPANIES = '/companies';
const CONTACTFORM = '/contact-form';
const DELIVERY_SLOTS = '/delivery-slots';
const DEPOSIT_BRIEFS = '/deposit-briefs';
const DOWNLOAD = '/download';
const FORGOT_PASSWORD = '/forgot-password';
const FOR_ORDER = '/for-order';
const HOLIDAYS = '/holidays';
const LOCATION = '/location';
const LOCATIONS = '/locations';
const LOGOUT = '/logout';
const NOTES = '/notes';
const OAUTH = '/oauth';
const ORDERS = '/orders';
const PAGE = page => `&page=${page}`;
const PAGINATED = '?paginated';
const PORTAL = '/portal';
const PRODUCTS = '/products';
const PROFILE = '/profile';
const RECURRING_ORDERS = '/recurring-orders';
const REPORTS = '/seal-bags';
const REGISTER = '/register';
const RESEND_INVITE = '/resend-invite';
const RESET_PASSWORD = '/reset-password';
const TOKEN = '/token';
const USERS = '/users';
const VALIDATE_SEALBAGNO = '/validate-sealbag';

// V2
export const ENDPOINTS = () => ({
	DELIVERY_SLOTS: {
		GET_BY_LOCATION_ID: _id => `${API}${PORTAL}${DELIVERY_SLOTS}${LOCATION}/${_id}`, // GET
		GET_BY_ID: _id => `${API}${PORTAL}${DELIVERY_SLOTS}/${_id}`, // GET
	},
	LOCATIONS: {
		ALL: `${API}${PORTAL}${LOCATIONS}`, // GET
		GET_BY_ID: _id => `${API}${PORTAL}${LOCATIONS}/${_id}`, // GET
	},
	HOLIDAYS: {
		ALL: `${API}${PORTAL}${HOLIDAYS}`, // GET
	},
	OAUTH: {
		LOGIN: `${OAUTH}${TOKEN}`, // POST
		FORGOT_PASSWORD: `${API}${FORGOT_PASSWORD}`, // POST
		REGISTER: `${OAUTH}${REGISTER}`, // POST
		RESET_PASSWORD: `${API}${RESET_PASSWORD}`, // POST
		LOGOUT: `${API}${LOGOUT}`, // POST
	},
	JUMBO: {
		REDIRECT: `${API}/jumbo/sso/auth`,
		CALLBACK: `${API}/jumbo/sso/auth/credentials`, // POST
	},
	ORDERS: {
		ALL: `${API}${PORTAL}${ORDERS}`, // GET
		PAGINATED: _page => `${API}${PORTAL}${ORDERS}${PAGINATED}${PAGE(_page)}`, // GET
		GET_BY_ID: _id => `${API}${PORTAL}${ORDERS}/${_id}`, // GET
		CREATE: `${API}${PORTAL}${ORDERS}`, // POST
		STORE: _id => `${API}${PORTAL}${ORDERS}/${_id}`, // POST
		DELETE: _id => `${API}${PORTAL}${ORDERS}/${_id}`, // DELETE
		DOWNLOAD: _id =>
			`${API}${PORTAL}${ORDERS}/${_id}${ATTACHMENTS}${DEPOSIT_BRIEFS}`, // GET
		DOWNLOAD_SPECIFICATION: (_order_id, _spec_id) =>
			`${API}${PORTAL}${ORDERS}/${_order_id}${ATTACHMENTS}${DEPOSIT_BRIEFS}/${_spec_id}`, // GET
	},
	RECURRING_ORDERS: {
		ALL: `${API}${PORTAL}${RECURRING_ORDERS}`, // GET
		STORE: `${API}${PORTAL}${RECURRING_ORDERS}`, // POST
		UPDATE: _id => `${API}${PORTAL}${RECURRING_ORDERS}/${_id}`, // PATCH
		DELETE: _id => `${API}${PORTAL}${RECURRING_ORDERS}/${_id}`, // DELETE
	},
	PRODUCTS: {
		GET_BY_ID: _id => `${API}${PORTAL}/${_id}${PRODUCTS}`, // GET
		VALIDATE_SEALBAGNO: `${API}${PORTAL}${PRODUCTS}${VALIDATE_SEALBAGNO}`, // POST
	},
	PROFILE: {
		WHOAMI: `${API}${PORTAL}${PROFILE}`, // GET
		UPDATE: `${API}${PORTAL}${PROFILE}`, // POST
	},
	COMPANIES: {
		ALL: `${API}${PORTAL}${COMPANIES}`, // GET
		GET_BY_ID: _id => `${API}${PORTAL}${COMPANIES}/${_id}`, // GET
	},
	USERS: {
		ALL: `${API}${PORTAL}${USERS}`, // GET
		PAGINATED: _page => `${API}${PORTAL}${USERS}${PAGINATED}${PAGE(_page)}`, // GET
		GET_BY_ID: _id => `${API}${PORTAL}${USERS}/${_id}`, // GET
		CREATE: `${API}${PORTAL}${USERS}`, // POST
		UPDATE: _id => `${API}${PORTAL}${USERS}/${_id}`, // PUT
		DELETE: _id => `${API}${PORTAL}${USERS}/${_id}`, // DELETE
		RESEND_INVITE: _id => `${API}${PORTAL}${USERS}/${_id}${RESEND_INVITE}`, // GET
	},
	CONTACT: {
		STORE: `${API}${PORTAL}${CONTACTFORM}`,
	},
	ANNOUNCEMENTS: {
		ALL: `${API}${PORTAL}${ANNOUNCEMENTS}`,
	},
	REPORTS: {
		NOTES: {
			ALL: `${API}${PORTAL}${REPORTS}${NOTES}`, // GET
			PAGINATED: _page =>
				`${API}${PORTAL}${REPORTS}${NOTES}${PAGINATED}${PAGE(_page)}`, // GET
			GET_BY_ID: _id => `${API}${PORTAL}${REPORTS}${NOTES}/${_id}`, // GET
			DOWNLOAD_OVERVIEW_REPORT: `${API}${PORTAL}${REPORTS}${NOTES}${DOWNLOAD}`, // GET
			DOWNLOAD_REPORT: _id => `${API}${PORTAL}${REPORTS}${NOTES}/${_id}${DOWNLOAD}`, // GET
		},
		COINS: {
			ALL: `${API}${PORTAL}${REPORTS}${COINS}`, // GET
			PAGINATED: _page =>
				`${API}${PORTAL}${REPORTS}${COINS}${PAGINATED}${PAGE(_page)}`, // GET
			GET_BY_ID: _id => `${API}${PORTAL}${REPORTS}${COINS}/${_id}`, // GET
			DOWNLOAD_OVERVIEW_REPORT: `${API}${PORTAL}${REPORTS}${COINS}${DOWNLOAD}`, // GET
			DOWNLOAD_REPORT: _id => `${API}${PORTAL}${REPORTS}${COINS}/${_id}${DOWNLOAD}`, // GET
		},
	},
});

export default ENDPOINTS;
