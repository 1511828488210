// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
	MAIN: 'ac-private-route',
};

class AcPrivateRouteController extends React.Component {
	getStyleClassNames() {
		return clsx(_CLASSES.MAIN);
	}
}

AcPrivateRouteController.propTypes = {};

AcPrivateRouteController.defaultProps = {};

export default AcPrivateRouteController;
