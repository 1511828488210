/* eslint react-app/import/no-webpack-loader-syntax: off */
import BACKGROUND_IMAGE from '@assets/images/background-alpha-1x.jpg';
import HERO_IMAGE from '@assets/images/hero/hero-default-2x.webp';
import LOADER from '-!svg-react-loader!@assets/images/loader-1x.svg';
import LOGO_FULL from '-!svg-react-loader!@assets/images/logo-full-2x.svg';
import LOGO from '@assets/images/logo-brand-1x.svg';

export const VISUALS = {
	BACKGROUND_IMAGE,
	HERO_IMAGE,
	LOADER,
	LOGO,
	LOGO_FULL,
};
export { BACKGROUND_IMAGE, HERO_IMAGE, LOADER, LOGO, LOGO_FULL };
