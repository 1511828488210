// Imports => MOBX
import { observable, computed, action } from 'mobx';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcClearState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
} from '@utils';

let app = {};
let timer = null;

export class AuthStore {
	constructor(store) {
		dayjs.extend(advancedFormat);

		AcAutoLoad(this, KEYS.ACCOUNT);
		AcAutoLoad(this, KEYS.ACCESS_TOKEN);
		AcAutoLoad(this, KEYS.EXPIRES_IN);
		AcAutoLoad(this, KEYS.EXPIRES_AT);
		AcAutoLoad(this, KEYS.REFRESH_TOKEN);
		AcAutoLoad(this, KEYS.ROLE);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	access_token = null;

	@observable
	expires_in = null;

	@observable
	expires_at = null;

	@observable
	account = null;

	@observable
	role = null;

	@observable
	loading = false;

	@computed
	get current_access_token() {
		return this.access_token;
	}

	@computed
	get current_expires_at() {
		return this.expires_at;
	}

	@computed
	get current_account() {
		return this.account;
	}

	@computed
	get current_role() {
		return this.role;
	}

	@computed
	get current_permissions() {
		if (!this.current_account || !this.current_account.permissions) return [];
		return this.current_account && this.current_account.permissions.map(n => n.name);
	}

	@computed
	get is_authorized() {
		let authorized = false;

		let session = {
			access_token: this.current_access_token,
			expires_at: this.current_expires_at,
			user: this.current_account,
		};

		if (session.access_token && session.expires_at && session.user) {
			let expiresAt = JSON.parse(session.expires_at);
			let now = new Date().getTime();

			console.group('[Store: Auth] => computed.isAuthorized');
			console.log('Session: ', session);
			console.log('Now: ', now, new Date(now));
			console.log('Expires at: ', expiresAt, new Date(expiresAt));
			console.log('User: ', session.user);

			authorized = session.user && session.access_token && now < expiresAt;

			console.log('Is Authorized: ', authorized);
			console.groupEnd();
		}

		return authorized;
	}

	@action
	setLoading(state, message) {
		this.loading = {
			status: state || false,
			message: message || false,
		};
	}

	@action
	async clearAuthentication() {
		await this.set(KEYS.ACCOUNT, null, true);
		await this.set(KEYS.ROLE, null, true);
		await this.set(KEYS.ACCESS_TOKEN, null, true);
		await this.set(KEYS.REFRESH_TOKEN, null, true);
		await this.set(KEYS.EXPIRES_IN, null, true);
		await this.set(KEYS.EXPIRES_AT, null, true);
		await app.store.announcements.set(KEYS.SEEN, []);
		AcClearState();
	}

	@action
	handleAuthentication(result) {
		return new Promise(resolve => {
			if (result && result.user_role) this.set(KEYS.ROLE, result.user_role, true);
			if (result && result.access_token)
				this.set(KEYS.ACCESS_TOKEN, result.access_token, true);
			if (result && result.refresh_token)
				this.set(KEYS.REFRESH_TOKEN, result.refresh_token, true);
			if (result && result.expires_in) {
				let now = new Date();
				let expires_at = now.getTime() + result.expires_in;

				this.set(KEYS.EXPIRES_IN, result.expires_in, true);
				this.set(KEYS.EXPIRES_AT, expires_at, true);
			}
			if (result) this.set(KEYS.ACCOUNT, result, true);
			resolve();
		});
	}

	@action
	login(credentials) {
		if (!credentials) return;

		this.setLoading(true, 'Verifying authentication credentials');

		return app.store.api.auth
			.login(credentials)
			.then(async response => {
				await this.handleAuthentication(response);
				app.store.locations.set(KEYS.LOCATION, null);

				await app.store.profile.who_am_i();
				return response;
			})
			.catch(error => {
				this.setLoading(false);
				this.clearAuthentication(error);

				const code = AcFormatErrorCode(error);
				let description = AcFormatErrorMessage(error);

				switch (code) {
					case 400:
						description = 'De gebruikersgegevens zijn onjuist.';
						break;

					case 401:
						description = 'Onvoldoende rechten voor deze actie.';
						break;

					default:
				}

				app.store.toasters.add({
					variant: 'error',
					title: 'Login failed',
					description,
					code,
				});

				throw error;
			});
	}

	@action
	jumbo_sso(credentials) {
		if (!credentials) return;

		this.setLoading(true, 'Verifying authentication credentials');

		return app.store.api.auth
			.sso(credentials)
			.then(response => {
				this.handleAuthentication(response);
				app.store.locations.set(KEYS.LOCATION, null);
				return response;
			})
			.catch(error => {
				this.setLoading(false);
				this.clearAuthentication(error);

				app.store.toasters.add({
					variant: 'error',
					title: 'Login failed',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});
			});
	}

	@action
	forgot_password(credentials) {
		if (!credentials) return;

		this.setLoading(true);

		return app.store.api.auth
			.forgot_password(credentials)
			.then(response => {
				app.store.toasters.add({
					variant: 'success',
					title: 'Successfully requested a new password',
				});

				this.setLoading(false);
				return response;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: 'Failed to request a new password',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	}

	@action
	reset_password(credentials) {
		if (!credentials) return;

		this.setLoading(true);

		return app.store.api.auth
			.reset_password(credentials)
			.then(response => {
				app.store.toasters.add({
					variant: 'success',
					title: 'New password is stored',
				});

				this.setLoading(false);
				return response;
			})
			.catch(error => {
				this.clearAuthentication(error);

				app.store.toasters.add({
					variant: 'error',
					title: 'Failed to store your new password',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	}

	@action
	whoami() {
		this.setLoading(true);

		return app.store.api.auth
			.whoami()
			.then(response => {
				this.handleAuthentication(response);

				this.setLoading(false);
				return response;
			})
			.catch(error => {
				this.clearAuthentication(error);

				this.setLoading(false);
				throw error;
			});
	}

	@action
	logout() {
		if (timer) clearTimeout(timer);
		this.setLoading(true);

		this.clearAuthentication();
	}

	@action
	set(key, value, save = false) {
		if (!key) return;
		if (typeof this[key] === 'undefined') return;
		this[key] = value;
		if (save) AcSaveState(key, value);
	}
}

export default AuthStore;
