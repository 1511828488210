// Imports => Utilities
import { AcUUID } from '@utils';

// Imports => Constants
import { KEYS, TITLES } from '@constants';

export const OrderSealbags = class {
	constructor(products) {
		this.products = products;

		return this.create();
	}

	create = () => {
		return {
			id: AcUUID(),
			type: KEYS.SEALBAGS,
			title: TITLES.ORDER_SEALBAGS,
			products: this.products.map(product => ({ ...product, value: '' })),
		};
	};
};

export default OrderSealbags;
