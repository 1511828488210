export const ICONS = {
	ACCOUNT: 'account',
	ACCOUNT_CARD_DETAILS_OUTLINE: 'account-card-details-outline',
	ACCOUNT_CIRCLE_OUTLINE: 'account-circle-outline',
	ACCOUNT_EDIT: 'account-edit',
	ACCOUNT_MINUS: 'account-minus',
	ACCOUNT_MINUS_OUTLINE: 'account-minus-outline',
	ACCOUNT_OUTLINE: 'account-outline',
	ACCOUNT_PLUS: 'account-plus',
	ACCOUNT_PLUS_OUTLINE: 'account-plus-outline',
	ACCOUNT_REMOVE: 'account-remove',
	ACCOUNT_REMOVE_OUTLINE: 'account-remove-outline',
	ACCOUNT_SEARCH: 'account-search',
	ACCOUNT_SETTINGS: 'account-settings',
	ACCOUNT_SETTINGS_OUTLINE: 'account-settings-outline',
	ALERT_CIRCLE_OUTLINE: 'alert-circle-outline',
	ALERT_OUTLINE: 'alert-outline',
	APPS: 'apps',
	ARROW_ALL: 'arrow-all',
	ARROW_BOTTOM_LEFT: 'arrow-bottom-left',
	ARROW_BOTTOM_RIGHT: 'arrow-bottom-right',
	ARROW_COMPRESS: 'arrow-compress',
	ARROW_COMPRESS_ALL: 'arrow-compress-all',
	ARROW_DOWN: 'arrow-down',
	ARROW_EXPAND: 'arrow-expand',
	ARROW_EXPAND_ALL: 'arrow-expand-all',
	ARROW_LEFT: 'arrow-left',
	ARROW_RIGHT: 'arrow-right',
	ARROW_TOP_LEFT: 'arrow-top-left',
	ARROW_TOP_RIGHT: 'arrow-top-right',
	ARROW_UP: 'arrow-up',
	AT: 'at',
	BACKBURGER: 'backburger',
	BALLOT_OUTLINE: 'ballot-outline',
	BELL: 'bell',
	BELL_OUTLINE: 'bell-outline',
	CALENDAR_BLANK: 'calendar-blank',
	CAR: 'car',
	CELLPHONE: 'cellphone',
	CHECK: 'check',
	CHECK_ALL: 'check-all',
	CHECK_CIRCLE_OUTLINE: 'check-circle-outline',
	CHEVRON_DOWN: 'chevron-down',
	CHEVRON_LEFT: 'chevron-left',
	CHEVRON_RIGHT: 'chevron-right',
	CHEVRON_UP: 'chevron-up',
	CLOCK: 'clock',
	CLOSE: 'close',
	COINS: 'coins',
	COMMENT_ALERT: 'comment-alert',
	COMMENT_ALERT_OUTLINE: 'comment-alert-outline',
	COMMENT_PROCESSING: 'comment-processing',
	COMMENT_PROCESSING_OUTLINE: 'comment-processing-outline',
	COPYRIGHT: 'copyright',
	CREDIT_CARD_MULTIPLE: 'credit-card-multiple',
	DELETE: 'delete',
	DELETE_CIRCLE_OUTLINE: 'delete-circle-outline',
	DELETE_OUTLINE: 'delete-outline',
	DELIVERY: 'delivery',
	DOMAIN: 'domain',
	DOTS_HORIZONTAL: 'dots-horizontal',
	DOTS_VERTICAL: 'dots-vertical',
	DOWNLOAD: 'download',
	EMAIL: 'email',
	EMAIL_OUTLINE: 'email-outline',
	EMAIL_SEND: 'email-send',
	EMAIL_SEND_OUTLINE: 'email-send-outline',
	EXCLAMATION: 'exclamation',
	EYE: 'eye',
	EYE_CIRCLE: 'eye-circle',
	EYE_CIRCLE_OUTLINE: 'eye-circle-outline',
	EYE_OFF: 'eye-off',
	EYE_OFF_OUTLINE: 'eye-off-outline',
	EYE_OUTLINE: 'eye-outline',
	FACEBOOK: 'facebook',
	FILE_DOCUMENT_BOX_OUTLINE: 'file-document-box-outline',
	FILE_DOCUMENT_OUTLINE: 'file-document-outline',
	FILE_EXCEL_BOX: 'file-excel-box',
	FILE_PDF_BOX: 'file-pdf-box',
	FILE_POWERPOINT_BOX: 'file-powerpoint-box',
	FILE_WORD_BOX: 'file-word-box',
	FILTER: 'filter',
	FILTER_OUTLINE: 'filter-outline',
	FILTER_VARIANT: 'filter-variant',
	FORMAT_LIST_BULLETED: 'format-list-bulleted',
	FULLSCREEN: 'fullscreen',
	FULLSCREEN_EXIT: 'fullscreen-exit',
	GOOGLE_PLUS: 'google-plus',
	GRID: 'grid',
	GRID_LARGE: 'grid-large',
	HELP: 'help',
	HEXAGON: 'hexagon',
	HEXAGON_OUTLINE: 'hexagon-outline',
	HOME: 'home',
	INFORMATION_OUTLINE: 'information-outline',
	INFORMATION_VARIANT: 'information-variant',
	INSTAGRAM: 'instagram',
	LINKEDIN: 'linkedin',
	LOCK_RESET: 'lock-reset',
	LOGIN_VARIANT: 'login-variant',
	LOGOUT: 'logout',
	LOGOUT_CIRCLE_OUTLINE: 'logout-circle-outline',
	LOGOUT_VARIANT: 'logout-variant',
	MAGNIFY: 'magnify',
	MAGNIFY_MINUS_OUTLINE: 'magnify-minus-outline',
	MAGNIFY_PLUS_OUTLINE: 'magnify-plus-outline',
	MAIL_RU: 'mail-ru',
	MENU: 'menu',
	MENU_DOWN: 'menu-down',
	MENU_LEFT: 'menu-left',
	MENU_RIGHT: 'menu-right',
	MENU_UP: 'menu-up',
	MESSAGE_TEXT_OUTLINE: 'message-text-outline',
	MINUS: 'minus',
	MONITOR: 'monitor',
	MOUSE_OUTLINE: 'mouse-outline',
	OPEN_IN_NEW2: 'open-in-new2',
	OPEN_IN_NEW: 'open-in-new',
	PANORAMA_WIDE_ANGLE: 'panorama-wide-angle',
	PAPERCLIP: 'paperclip',
	PAUSE: 'pause',
	PENCIL: 'pencil',
	PHONE: 'phone',
	PICK_UP: 'pick-up',
	PICK_UP_DELIVERY: 'pick-up-delivery',
	PLAY: 'play',
	PLUS: 'plus',
	POWER: 'power',
	PROGRESS_DOWNLOAD: 'progress-download',
	PROJECTOR_SCREEN: 'projector-screen',
	RCCS: 'rccs',
	REFRESH: 'refresh',
	ROCKET: 'rocket',
	SECURITY_LOCK: 'security-lock',
	SETTINGS: 'settings',
	SETTINGS_OUTLINE: 'settings-outline',
	SHARE_VARIANT: 'share-variant',
	SHIELD_HALF_FULL: 'shield-half-full',
	SIGNAL_OFF: 'signal-off',
	STAR: 'star',
	STAR_HALF: 'star-half',
	STAR_OUTLINE: 'star-outline',
	SUBDIRECTORY_ARROW_LEFT: 'subdirectory-arrow-left',
	SUBDIRECTORY_ARROW_RIGHT: 'subdirectory-arrow-right',
	TAG_OUTLINE: 'tag-outline',
	TOGGLE_SWITCH: 'toggle-switch',
	TOGGLE_SWITCH_OFF: 'toggle-switch-off',
	TOGGLE_SWITCH_OFF_OUTLINE: 'toggle-switch-off-outline',
	TOGGLE_SWITCH_OUTLINE: 'toggle-switch-outline',
	TRASH_CAN: 'trash-can',
	TRASH_CAN_OUTLINE: 'trash-can-outline',
	TUNE: 'tune',
	TWITTER: 'twitter',
	UPLOAD: 'upload',
	VIMEO: 'vimeo',
	WATER: 'water',
	YOUTUBE_PLAY: 'youtube-play',
};
