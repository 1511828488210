export const DATETIME_FORMATS = {
	TIMESTAMP: 'X',
	FULL_DAY: 'dddd DD MMMM',
	FULL_DAY_WITH_YEAR: 'dddd DD MMMM YYYY',
	RAW_DATE: 'YYYY-MM-DD',
	DATE: 'DD-MM-YYYY',
	DATE_UNICODE: 'dd-MM-yyyy',
	DAY: 'DD',
	MONTH: 'MMMM',
	YEAR: 'YYYY',
	WEEKDAY: 'dddd',
	TIME: 'HH:mm',
};
