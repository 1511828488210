// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import { AcGetAccessToken, AcGetState } from '@utils';

// Get ENV variables
const _api_ = process.env.API_URL;
const _api_version_ = process.env.API_VERSION;

const _auto_logout = process.env.AUTO_LOGOUT;
const _auto_logout_time = process.env.AUTO_LOGOUT_TIME;

export default {
	autologout: {
		active: _auto_logout || false,
		time: _auto_logout_time || 0,
	},
	rollbar: {
		accessToken: process.env.ROLLBAR_KEY,
		captureUncaught: true,
		captureUnhandledRejections: true,
		verbose: false,
		payload: {
			environment: process.env.ROLLBAR_ENVIRONMENT,
		},
	},
	api: {
		version: `${_api_version_}`,
		baseURL: `${_api_}`,
		timeout: 1000 * 60,
		maxContentLength: 10000,
		responseType: 'json',
		responseEncoding: 'utf8',
		role: AcGetState(KEYS.ROLE),
		credentials: true,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		transformRequest: [
			(data, headers) => {
				const token = AcGetAccessToken();
				if (token) headers['authorization'] = `Bearer ${token}`;
				return JSON.stringify(data);
			},
		],
	},
	download: {
		version: `${_api_version_}`,
		baseURL: `${_api_}`,
		timeout: 1000 * 60,
		maxContentLength: 10000,
		responseType: 'blob',
		responseEncoding: 'utf8',
		role: AcGetState(KEYS.ROLE),
		credentials: true,
		transformRequest: [
			(data, headers) => {
				const token = AcGetAccessToken();
				if (token) headers['authorization'] = `Bearer ${token}`;
				return data;
			},
		],
	},
};
