// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Constants
import { ICONS, ROLES } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Controller
import AcUserNavigationController from './ac-user-navigation.controller';

// Imports => Atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

@inject('store', 'routing')
@observer
class AcUserNavigation extends AcUserNavigationController {
  getInitials(name) {
    if (!name) return '';
    const arr = name.split(' ');
    const first_letter = arr[0][0];
    const last_letter = arr.length > 1 ? arr[arr.length - 1][0] : arr[0][1];

    return `${first_letter}${last_letter}`;
  }

  getProfileName = () => {
    const { current_profile } = this.store.profile;
    let profile_name = `${current_profile.name}`;

    if (AcIsSet(current_profile.company_id)) {
      this.store.companies.get_by_id(current_profile.company_id).then(data => {
        profile_name = `${current_profile.name} <small>${data.name}</small>`;
        this.setState({ profile_name });
      });
    } else if (AcIsSet(current_profile.location_id)) {
      this.store.locations.get_by_id(current_profile.location_id).then(data => {
        profile_name = `${current_profile.name} <small>${data.name}</small>`;
        this.setState({ profile_name });
      });
    }

    this.setState({ profile_name });
  };

  render() {
    const { t } = this.props;
    const { current_profile } = this.store.profile;
    const { current_role } = this.store.auth;
    const { routes, profile_name } = this.state;

    const _routes = Object.keys(routes)
      .filter(item => routes[item].roles.indexOf(current_role) > -1)
      .map(item => routes[item]);

    return (
      <nav className={this.getStyleClassNames()} ref={this.$navigation}>
        <div className={this.getToggleClassNames()} onClick={this.handleToggle}>
          <span
            className={this.getToggleIconClassNames()}
            dangerouslySetInnerHTML={{
              __html: this.getInitials(current_profile.name),
            }}
          />
          <span
            className={this.getToggleLabelClassNames()}
            dangerouslySetInnerHTML={{
              __html: profile_name,
            }}
          />
          <AcIcon
            icon={ICONS.CHEVRON_DOWN}
            className={this.getPointerIconClassNames()}
          />
          <AcRipple size={'small'} simple />
        </div>

        <ul className={this.getListClassNames()}>
          {_routes &&
            _routes.map(item => (
              <li
                className={this.getListItemClassNames()}
                key={`nav-item-${item.id}`}
              >
                <div
                  className={this.getLinkClassNames(item)}
                  onClick={event => this.handleClick(event, item)}
                >
                  <AcIcon
                    icon={item.icon}
                    className={this.getLinkIconClassNames()}
                  />
                  {t(item.label)}
                  <AcRipple size={'small'} simple />
                </div>
              </li>
            ))}
          {current_role !== ROLES.ZIEMANN_ADMIN &&
            current_role !== ROLES.ZIEMANN_ANALYST &&
            current_role !== ROLES.ZIEMANN_FINANCE &&
            current_role !== ROLES.ZIEMANN_PLANNER && (
              <li className={this.getListItemClassNames()}>
                <div
                  className={this.getLinkClassNames()}
                  onClick={this.handleContactForm}
                >
                  <AcIcon
                    icon={ICONS.EMAIL_SEND_OUTLINE}
                    className={this.getLinkIconClassNames()}
                  />
                  Contact
                  <AcRipple size={'small'} simple />
                </div>
              </li>
            )}
          <li className={this.getListItemClassNames()}>
            <div className={this.getLinkClassNames()} onClick={this.handleLogout}>
              <AcIcon
                icon={ICONS.LOGOUT_CIRCLE_OUTLINE}
                className={this.getLinkIconClassNames()}
              />
              Uitloggen
              <AcRipple size={'small'} simple />
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

export default withNamespaces()(AcUserNavigation);
