// Imports => React
import React, { useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

// Imports => Atoms
import AcToaster from '@atoms/ac-toaster/ac-toaster.web';

const _CLASSES = {
	MAIN: 'ac-toaster-hoc',
	TRANSITION: 'ac-toaster-transition',
};

const AcToasterHoc = ({ queue, callback }) => {
	const handleCallback = useCallback(
		id => {
			if (callback) callback(id);
		},
		[callback]
	);

	const getStyleClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	return (
		<TransitionGroup className={getStyleClassNames}>
			{queue &&
				queue.map(toast => (
					<CSSTransition
						clsx={_CLASSES.TRANSITION}
						timeout={{ enter: 1000, exit: 1000 }}
						key={`ac-toaster-${toast.id}`}
					>
						<AcToaster {...toast} callback={handleCallback} />
					</CSSTransition>
				))}
		</TransitionGroup>
	);
};

export default React.memo(AcToasterHoc);
