// Imports => MOBX
import { observable, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import { AcAutoLoad, AcAutoSave, AcSaveState, AcClearState } from '@utils';

const _default = {
  options: {},
  products: null,
};

let app = {};

export class ProductsStore {
  constructor(store) {
    AcAutoLoad(this, KEYS.PRODUCTS);
    AcAutoLoad(this, KEYS.OPTIONS);
    AcAutoSave(this);

    app.store = store;
  }

  @observable
  products = [];

  @observable
  options = {};

  @observable
  loading = {
    status: false,
    message: null,
  };

  @action
  setLoading(state) {
    this.loading = {
      status: state || false,
      message: 'Opening the vault',
    };
  }

  @action
  get_by_id(id) {
    this.setLoading(true);

    return app.store.api.products
      .get_by_id(id)
      .then(response => {
        this.set(KEYS.PRODUCTS, response.data);

        this.setLoading(false);
        return response.data;
      })
      .catch(error => {
        this.setLoading(false);
        throw error;
      });
  }

  @action
  validate_sealbag_no(num) {
    this.setLoading(true);

    return app.store.api.products
      .validate_sealbag_no(num)
      .then(response => {
        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.setLoading(false);
        return error;
      });
  }

  @action
  set(target, value, save = false) {
    if (!target) return;
    if (typeof this[target] === 'undefined') return;
    if (typeof value === 'undefined' || value === null) return;

    this[target] = {
      ...this[target],
      ...value,
    };
    if (save) AcSaveState(target, this[target]);
  }

  @action
  setValue(target, property, value) {
    if (!target) return;
    if (typeof this[target] === 'undefined') return;
    if (!property) return;
    if (typeof value === 'undefined' || value === null) return;

    this[target][property] = value;
    AcSaveState(target, this[target]);
  }

  @action
  reset(target, save = false) {
    if (!target) return;
    if (typeof this[target] === 'undefined') return;

    this[target] = _default[target];
    AcClearState(target);
  }
}

export default ProductsStore;
