import React from 'react';
import ReactDOM from 'react-dom';

import { unregister } from './registerServiceWorker';

import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';

import dayjs from 'dayjs';
import 'dayjs/locale/nl';

import '@styles';
import '@config/i18n';
import '@utils/ac-array-helpers';

import config from '@config';
import createStore from '@stores';

import App from '@src/App';

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const store = createStore(config);

const storesCollection = {
	routing: routingStore,
	store,
};

const history = syncHistoryWithStore(browserHistory, routingStore);

dayjs.locale('nl');

ReactDOM.render(
	<Provider {...storesCollection}>
		<Router history={history}>
			<App />
		</Router>
	</Provider>,
	document.getElementById('root')
);

// registerSW();
unregister();
