// Imports => Moment
import dayjs from 'dayjs';

// Imports => Constants
import { DATETIME_FORMATS } from '@constants';

// Imports => Utilities
import { AcGetDaysInRange } from '@utils';

export const DeliverySlot = class {
	constructor(slots, locations, holidays) {
		this.ranges = [];
		this.slots = slots;
		this.locations = locations;
		this.holidays = holidays;

		return new Promise(resolve => {
			resolve(this.init());
		});
	}

	init = () => {
		// AcGetDaysInRange(_start, _end, _no_weekends)
		// AcGetDaysInRange(<dayjs>, <dayjs>, <boolean>)
		this.ranges = AcGetDaysInRange(dayjs(), dayjs().add(4, 'week'), true);

		return this.parseData();
	};

	parseData = () => {
		return new Promise(resolve => {
			let result = {};

			const ranges = this.ranges;
			const slots = this.slots;
			const locations = this.locations;
			const today = dayjs();

			result = ranges.map((range, n) => {
				const slot = this.getSlot(slots, range);
				const location = this.getLocation(locations, slot);
				const available = slot && slot.type;
				const orders = slot && slot.orders;
				const date = dayjs(range.timestamp);
				const holiday = this.getHoliday(
					dayjs(date).format(DATETIME_FORMATS.RAW_DATE),
					slots
				);

				const is_today = date.isSame(today, 'day');
				const is_before_today = date.isBefore(today, 'day');

				return {
					id: `delivery-slot-${n}`,
					slot: slot && slot.id,
					holiday,
					available,
					location,
					orders,
					date: {
						is_today,
						is_before_today,
						time_stamp: dayjs(date).toISOString(),
						weekday: dayjs(date).format(DATETIME_FORMATS.WEEKDAY),
						day: dayjs(date).format(DATETIME_FORMATS.DAY),
						month: dayjs(date).format(DATETIME_FORMATS.MONTH),
						year: dayjs(date).format(DATETIME_FORMATS.YEAR),
						week: dayjs(date).isoWeek(),
					},
				};
			});

			resolve(result);
		});
	};

	getHoliday = (date, slots) => {
		if (!date) return;

		const collection = this.holidays;
		let len = collection.length;
		let n = 0;
		let result = null;

		// here we loop through all holidays
		// and check if the holidays actually replace available delivery slots
		// since we only need to display the holidays that actually replace delivery slots
		// so the transport schedule UI does not get cluttered with unneccessary 'replacement dates'
		// that do not apply to the current user + location

		for (n; n < len; n++) {
			const item = collection[n];
			const is_replacement =
				slots && slots.find(slot => slot.date === item.replacement_date);

			if (item.date === date && is_replacement) {
				result = item.name;
				break;
			}
		}

		return result;
	};

	getLocation = (locations, slot) => {
		if (!slot) return;

		let len = locations.length;
		let n = 0;
		let result = null;

		for (n; n < len; n++) {
			const location = locations[n];

			if (location.id === slot.location_id) {
				result = location;
				break;
			}
		}

		return result;
	};

	getSlot = (slots, range) => {
		let len = slots.length;
		let n = 0;
		let result = null;

		for (n; n < len; n++) {
			const slot = slots[n];
			const a = dayjs(slot.date, 'YYYY-MM-DD');
			const b = dayjs(range.timestamp);

			if (a.isSame(b, 'day')) {
				result = slot;
				break;
			}
		}

		return result;
	};
};
