// Imports => MOBX
import { observable, action } from 'mobx';

// Imports => Constants
import { KEYS, ROLES } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

const _default = {
	options: {},
	fields: {
		subject: {
			value: '',
			required: true,
			valid: false,
		},
		content: {
			value: '',
			required: true,
			valid: false,
		},
		location_id: {
			value: '',
			required: true,
			valid: false,
		},
	},
};

let app = {};

export class ContactStore {
	constructor(store) {
		app.store = store;
	}

	@observable
	fields = {
		..._default.fields,
		location_id: {
			..._default.fields.location_id,
			required:
				app.store.auth.current_role === ROLES.MANAGER ||
				app.store.auth.current_role === ROLES.ANALYST,
		},
	};

	@observable
	loading = {
		status: false,
		message: null,
	};

	@action
	setLoading(state) {
		this.loading = {
			status: state || false,
			message: 'Delivering your input',
		};
	}

	@action
	send = () => {
		this.setLoading(true);

		/*
			subject
			content
			location_id [only if MANAGER or ADMIN]
		*/
		const fields = this.fields;
		let data = {};
		Object.keys(fields)
			.filter(key => {
				if (key === 'location_id') {
					return (
						app.store.auth.current_role === ROLES.MANAGER ||
						app.store.auth.current_role === ROLES.ANALYST
					);
				}
				return true;
			})
			.forEach(key => {
				data[key] = fields[key].value;
			});

		return app.store.api.contact
			.store(data)
			.then(response => {
				this.setLoading(false);
				return response;
			})
			.catch(error => {
				this.setLoading(false);
				throw error;
			});
	};

	@action
	set(target, value, save = false) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;
		if (typeof value === 'undefined' || value === null) return;

		this[target] = value;
	}

	@action
	setValue(target, property, value) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;
		if (!property) return;
		if (typeof value === 'undefined' || value === null) return;

		this[target][property].value = value;
		this[target][property].valid = AcIsSet(value);
	}

	@action
	reset(target) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;

		if (target === KEYS.FIELDS) {
			this[target] = {
				..._default.fields,
				location_id: {
					..._default.fields.location_id,
					required:
						app.store.auth.current_role === ROLES.MANAGER ||
						app.store.auth.current_role === ROLES.ANALYST,
				},
			};
		} else {
			this[target] = _default[target];
		}
	}
}

export default ContactStore;
