import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/nl';

export const AcGetDaysInRange = (start, end, no_weekends) => {
	dayjs.extend(isoWeek);

	let days = [];
	let first_day = dayjs().startOf('week');
	let last_day = dayjs()
		.add(1, 'month')
		.endOf('week');

	let _start = start ? dayjs(start).startOf('week') : first_day;
	let _end = end ? dayjs(end).endOf('week') : last_day;

	_start = new Date(_start);
	_end = new Date(_end);

	while (_start < _end) {
		const _s = dayjs(_start);

		if (no_weekends) {
			// if (_start.isoWeekday() !== 6 && _start.isoWeekday() !== 7) {
			if (_s.isoWeekday() !== 7) {
				days.push({
					timestamp: _s.startOf('day').clone(),
					date: _s.format('DD-MM-YYYY'),
				});
			}
		} else {
			days.push({
				timestamp: _s.startOf('day').clone(),
				date: _s.format('DD-MM-YYYY'),
			});
		}

		_start.setDate(_start.getDate() + 1);
	}

	return days;
};

export default AcGetDaysInRange;
