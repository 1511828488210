// Imports => MOBX
import { observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import { AcAutoLoad, AcAutoSave, AcSaveState } from '@utils';

const _default = {
	options: {},
	company: {},
	companies: [],
};

let app = {};

export class CompaniesStore {
	constructor(store) {
		AcAutoLoad(this, KEYS.COMPANIES);
		AcAutoLoad(this, KEYS.COMPANY);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	company = {};

	@observable
	companies = [];

	@observable
	loading = {
		status: false,
		message: null,
	};

	@computed
	get current_company() {
		let result = null;

		if (this.company) {
			result = this.company;
		} else if (this.companies && this.companies.length > 0) {
			result = this.companies[0];
		}

		return result;
	}

	@action
	setLoading(state) {
		this.loading = {
			status: state || false,
			message: 'Gathering companies',
		};
	}

	@action
	all() {
		this.setLoading(true);

		return app.store.api.companies
			.get()
			.then(response => {
				this.set(KEYS.COMPANIES, response.data);

				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				this.setLoading(false);
				throw error;
			});
	}

	@action
	get_by_id(id) {
		this.setLoading(true);

		return app.store.api.companies
			.get_by_id(id)
			.then(response => {
				this.set(KEYS.COMPANY, response.data);

				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				this.setLoading(false);
				throw error;
			});
	}

	@action
	set(target, value, save = false) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;
		if (typeof value === 'undefined' || value === null) return;

		this[target] = value;
		if (save) AcSaveState(target, this[target]);
	}

	@action
	setValue(target, property, value) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;
		if (!property) return;
		if (typeof value === 'undefined' || value === null) return;

		this[target][property] = value;
		AcSaveState(target, this[target]);
	}

	@action
	reset(target) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;

		this[target] = _default[target];
		AcSaveState(target, this[target]);
	}
}

export default CompaniesStore;
