// Imports => MOBX
import { observable, action, computed, set } from 'mobx';
import dayjs from 'dayjs';

// Imports => Constants
import { DATETIME_FORMATS, KEYS, MIMETYPES } from '@constants';

// Imports => Utilities
import {
	AcIsSet,
	AcIsString,
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcGetState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
	AcDownloadFile,
} from '@utils';

const _default = {
	options: {},
	meta: {},
	report: {},
	reports: [],
	type: null,
	filters: {
		locations: ['all'],
	},
	sortby: {
		field: KEYS.NAME,
		direction: KEYS.ASCENDING,
	},
};

let app = {};

export class ReportsStore {
	constructor(store) {
		AcAutoSave(this);

		const filters = AcGetState('filters');
		const type = AcGetState('type');
		if (filters && filters.date_end && filters.date_start) {
			const start = new Date(filters.date_start);
			const end = new Date(filters.date_end);

			this.set('report_date', { start, end });
			this.set('filters', {
				...filters,
				date_start: start,
				date_end: end,
				report_date: `${filters.date_start} ${filters.date_end}`,
			});
		}
		if (type) {
			this.set('type', type);
		}

		app.store = store;
	}

	@observable
	report = null;

	@observable
	reports = [];

	@observable
	type = null;

	@observable
	meta = {};

	@observable
	sortby = {
		field: KEYS.NAME,
		direction: KEYS.ASCENDING,
	};

	@observable
	filters = _default.filters;

	@observable
	options = {};

	@observable
	query = '';

	@observable
	report_date = {
		start: null,
		end: null,
	};

	@observable
	loading = {
		status: false,
		message: null,
	};

	@computed
	get current_type() {
		return this.type;
	}

	@computed
	get current_report_date() {
		return this.report_date;
	}

	@computed
	get current_filters() {
		return this.filters;
	}

	@action
	setLoading(state, message) {
		this.loading = {
			status: state || false,
			message: message || 'Gathering reports',
		};
	}

	@action
	setSortBy(field) {
		let sortby = {
			field,
			direction: this.sortby.direction,
		};

		if (this.sortby.field === field) {
			sortby.direction =
				sortby.direction === KEYS.ASCENDING ? KEYS.DESCENDING : KEYS.ASCENDING;
		} else if (this.sortby.field !== field) {
			sortby.direction = KEYS.DESCENDING;
		}

		this.set(KEYS.SORTBY, sortby);
	}

	@action
	getSortBy() {
		let result = this.sortby.field;
		result = this.sortby.direction === KEYS.ASCENDING ? result : `-${result}`;
		return result;
	}

	@action
	resetSortBy() {
		this.set(KEYS.SORTBY, _default.sortby);
	}

	@action
	setFilters = (name, value) => {
		if (!AcIsSet(name)) return;
		if (!AcIsSet(KEYS.FILTERS)) return;

		const filters = this.current_filters;
		const result = { ...filters, [name]: value };

		this.set(KEYS.FILTERS, result, true);
	};

	@action
	getFilters = () => {
		return JSON.parse(JSON.stringify(this.current_filters));
	};

	@action
	resetFilters = () => {
		this.set(KEYS.FILTERS, _default.filters, true);
		this.set('type', 'notes', true);
		this.set('report_date', { start: null, end: null }, true);
	};

	@action
	all() {
		this.setLoading(true);

		return app.store.api.reports
			.get()
			.then(response => {
				this.set(KEYS.REPORTS, response.data);

				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: 'Failed to load reports',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	}

	@action
	all_paginated(page) {
		this.setLoading(true);

		const _current_page = this.meta.current_page;
		const _page = AcIsSet(page)
			? page
			: AcIsSet(_current_page)
			? _current_page
			: 1;
		const _sort = this.getSortBy();
		const _type = this.type || 'notes';
		let _filters = this.getFilters();

		if (_filters.locations.indexOf('all') > -1) {
			const { locations } = app.store.locations;
			_filters.locations = locations.map(n => n.id);
		}

		return app.store.api.reports
			.get_paginated(_page, _sort, _filters, _type)
			.then(response => {
				this.set(KEYS.REPORTS, response.data);
				this.set(KEYS.META, { ...response.meta, ...response.links });

				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: 'Failed to load reports',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	}

	@action
	get_by_id(id, type) {
		this.setLoading(true);

		const _type = this.type || 'notes';

		return app.store.api.reports
			.get_by_id(id, _type)
			.then(response => {
				this.set(KEYS.REPORT, response.data);

				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: 'Failed to load report',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	}

	@action
	download_overview_report(collection, type, format = 'pdf') {
		this.setLoading(true, 'Generating download...');

		const _filters = this.getFilters();

		return app.store.api.reports
			.download_overview_report(collection, type, format, _filters)
			.then(response => {
				this.setLoading(false);

				let filename = response.headers['content-disposition']
					.split('filename=')[1]
					.split('.')[0];
				let extension = response.headers['content-disposition']
					.split('.')[1]
					.split(';')[0];

				AcDownloadFile(
					response.data,
					`${filename}.${extension}`,
					MIMETYPES[format.toUpperCase()]
				);

				return response;
			})
			.catch(error => {
				this.setLoading(false);
				throw error;
			});
	}

	@action
	download_report(report_id, type, format = 'pdf') {
		this.setLoading(true, 'Generating download...');

		return app.store.api.reports
			.download_report(report_id, type, format)
			.then(response => {
				this.setLoading(false);

				let filename = response.headers['content-disposition']
					.split('filename=')[1]
					.split('.')[0];
				let extension = response.headers['content-disposition']
					.split('.')[1]
					.split(';')[0];

				AcDownloadFile(
					response.data,
					`${filename}.${extension}`,
					MIMETYPES[format.toUpperCase()]
				);
			})
			.catch(error => {
				this.setLoading(false);
				throw error;
			});
	}

	@action
	set(target, value, save = false) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;
		if (typeof value === 'undefined' || value === null) return;

		this[target] = value;
		if (save) AcSaveState(target, this[target]);
	}

	@action
	setValue(target, property, value, save = false) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;
		if (!property) return;
		if (typeof value === 'undefined' || value === null) return;

		this[target][property] = value;
		if (save) AcSaveState(target, this[target]);
	}

	@action
	reset(target, save = false) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;

		this[target] = _default[target];
		if (save) AcSaveState(target, this[target]);
	}
}

export default ReportsStore;
