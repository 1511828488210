// Imports => React
import React from 'react';
import clsx from 'clsx';
import Pulse from 'react-reveal/Pulse';

// Imports => Constants
import { ICONS } from '@constants';

// Imports => Atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcStickyBar from '@atoms/ac-sticky-bar/ac-sticky-bar.web';

const _CLASSES = {
	MAIN: 'ac-announcement',
	CLOSE_ICON: 'ac-announcement__close-icon',
	CONTAINER: 'ac-announcement__container',
	TITLE: 'ac-announcement__title',
	CONTENT: 'ac-announcement__content',
};

// Component
const AcAnnouncement = ({
	id,
	title,
	content,
	seen,
	position,
	className,
	onClose,
}) => {
	const handleCloseClick = event => {
		if (onClose) onClose(id);
	};

	const getContentClassNames = () => {
		return clsx(_CLASSES.CONTENT);
	};

	const getTitleClassNames = () => {
		return clsx(_CLASSES.TITLE);
	};

	const getContainerClassNames = () => {
		return clsx(_CLASSES.CONTAINER);
	};

	const getCloseIconClassNames = () => {
		return clsx(_CLASSES.CLOSE_ICON);
	};

	const getStyleClassNames = () => {
		return clsx(_CLASSES.MAIN, className && className);
	};

	return (
		<AcStickyBar position={position || 'top'}>
			<Pulse duration={250} key={`ac-announcement-${id}`}>
				<div className={getStyleClassNames()}>
					<div className={getContainerClassNames()}>
						{title && <div className={getTitleClassNames()}>{title}</div>}
						{content && <div className={getContentClassNames()}>{content}</div>}
					</div>

					<div className={getCloseIconClassNames()} onClick={handleCloseClick}>
						<AcIcon icon={ICONS.CLOSE} />
					</div>
				</div>
			</Pulse>
		</AcStickyBar>
	);
};

export default React.memo(AcAnnouncement);
