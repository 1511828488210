export const TITLES = {
	ACTIVATE: 'Activate',
	ADD_USER: 'Add user',
	BACK_TO_LOGIN: 'Return to login',
	BASE: 'Ziemann Portal',
	CHANGE: 'Change',
	CHANGE_PASSWORD: 'Change password',
	COINBAGS: 'Coin bags',
	COINROLLS: 'Coin rolls',
	COINS: 'Coins',
	COMPANIES: 'Companies',
	COMPANY: 'Company',
	CONFIRM_PASSWORD: 'Confirm your password',
	CONTACT_FORM: 'Contact form',
	CURRENT_PASSWORD: 'Current password',
	DEACTIVATE: 'Deactivate',
	DELETE_USER: 'Delete user',
	DELETE_ORDER: 'Delete order',
	DELIVER: 'Deliver',
	DELIVERY: 'Delivery',
	DELIVERY_DATE: 'Delivery date',
	DELIVERY_ORDER: 'Delivery order',
	DELIVERY_ORDERS: 'Delivery orders',
	DETAILS: 'Details',
	EMAIL_SEND: 'Email has been sent',
	EMAILADDRESS: 'Emailaddress',
	END_DATE: 'End date',
	FILTER: 'Filter',
	FILTER_DATE: 'Filter op datum',
	FILTER_DELIVERY_DATE: 'Filter on delivery date',
	FILTER_ORDER_DATE: 'Filter on order date',
	FILTERS: 'Filters',
	FOILBAGS: 'Foil bags',
	FORGOT_PASSWORD: 'Forgot password',
	FORGOT_YOUR_PASSWORD: 'Forgot your password?',
	RECURRING_ORDER_OVERVIEW: 'Recurring orders',
	NEW_RECURRING_ORDER: 'New recurring order',
	FROM: 'From',
	FUNCTION: 'Function',
	KEYBOARD_SHORTCUTS: 'Keyboard shortcuts',
	LOCATION: 'Location',
	LOCATIONS: 'Locations',
	LOGIN: 'Login',
	MY_ACCOUNT: 'My Account',
	MY_ACCOUNT_DETAILS: 'My Details',
	MY_COMPANY: 'My Company',
	MY_LOCATIONS: 'My Locations',
	NAME: 'Name',
	NEW_ORDER: 'New Order',
	NEW_PASSWORD: 'New password',
	NEW_PASSWORD_CONFIRMATION: 'Repeat new password',
	NEW_PASSWORD_SAVED: 'Saved new password',
	NOTES: 'Notes',
	ORDER: 'Order',
	ORDER_CHANGE: 'Order Change',
	ORDER_DATE: 'Order date',
	ORDER_DETAILS: 'Order Details',
	ORDER_HISTORY: 'Order History',
	ORDER_OVERVIEW: 'Order Overview',
	ORDER_SEALBAGS: 'Order Sealbags',
	ORDER_STAMPS: 'Order Stamps',
	ORDERS: 'Orders',
	PASSWORD: 'Password',
	PICKUP: 'Pick-up',
	PICKUP_CASH: 'Pick-up Cash',
	PICKUP_ORDER: 'Pick-up order',
	PICKUP_ORDERS: 'Pick-up orders',
	REGISTER: 'Register',
	REPORTS: 'Telgegevens',
	REPORTS_OVERVIEW: 'Telgegevens',
	REPORTS_DETAIL: 'Telgegevens',
	REPORT_DATE: 'Rapportage datum',
	RESET_PASSWORD: 'Reset password',
	ROLE: 'Role',
	SAVE_PASSWORD: 'Save password',
	SEALBAG: 'Sealbag',
	SEALBAG_NUMBER: 'Sealbagnumber',
	SEALBAGS: 'Sealbags',
	SELECT_END_DATE: 'Click to select an end date',
	SELECT_START_DATE: 'Click to select a start date',
	STAMPS: 'Stamps',
	START_DATE: 'Start date',
	TO: 'To',
	TO_LOGIN: 'To login',
	TRANSPORT_SCHEDULE: 'Transport Schedule',
	UPDATE_USER: 'Update user',
	USER_OVERVIEW: 'User Overview',
	USER_ROLE: 'User role',
	USERNAME: 'Username',
	YOUR_DELIVERY_ORDER: 'Your delivery orders',
	YOUR_PICKUP_ORDER: 'Your pick-up orders',
};
