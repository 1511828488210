// Imports => MOBX
import { observable, computed, action, toJS } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
} from '@utils';

let app = {};

export class SlotsStore {
	constructor(store) {
		AcAutoLoad(this, KEYS.LOCATIONS);
		AcAutoLoad(this, KEYS.LOCATION);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	location = null;

	@observable
	locations = [];

	@observable
	loading = {
		status: false,
		message: null,
	};

	@computed
	get current_location() {
		let result = null;

		if (this.location) {
			result = this.location;
		} else if (this.locations && this.locations.length > 0) {
			result = this.locations[0];
		}

		return result;
	}

	@computed
	get current_locations() {
		return toJS(this.locations);
	}

	@action
	setLoading(state) {
		this.loading = {
			status: state || false,
			message: 'Mapping your locations',
		};
	}

	@action
	all = (options = {}) => {
		this.setLoading(true);
		return app.store.api.locations
			.get(options)
			.then(async response => {
				await this.set(KEYS.LOCATIONS, response.data);
				const location = this.location || (response.data && response.data[0]);
				await this.set(KEYS.LOCATION, location);

				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: 'Laden van locaties is niet gelukt',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	@action
	get_by_id(_id) {
		if (typeof _id === 'undefined' || _id === null) return;
		this.setLoading(true);
		return app.store.api.locations
			.get_by_id(_id)
			.then(async response => {
				await this.set(KEYS.LOCATION, response.data && response.data);

				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: 'Het ophalen van locatiegegevens is niet gelukt',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	}

	@action
	set(target, value, save = true) {
		if (typeof value === 'undefined') return;
		if (typeof target === 'undefined') return;
		if (typeof this[target] === 'undefined') return;

		this[target] = value;
		if (save) AcSaveState(target, this[target]);
	}
}

export default SlotsStore;
