// Imports => React
import React, { useCallback, memo } from 'react';
import clsx from 'clsx';

const _CLASSES = {
	MAIN: 'ac-logo',
	LIGHT: 'ac-logo--light',
	DARK: 'ac-logo--dark',
	ALPHA: 'ac-logo--alpha',
	CONTAINER: {
		MAIN: 'ac-logo__container',
	},
	VISUAL: {
		MAIN: 'ac-logo__visual',
	},
};

// Component
const AcLogo = ({ visual: Visual, theme = 'default', callback }) => {
	const getContainerClassNames = useCallback(() => {
		return clsx(_CLASSES.CONTAINER.MAIN);
	}, []);

	const getStyleClassNames = useCallback(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	return (
		<div className={getStyleClassNames()} onClick={callback}>
			<div className={getContainerClassNames()}>
				<Visual alt={'logo ziemann'} style={{ width: '12rem' }} />
			</div>
		</div>
	);
};

export default memo(AcLogo);
