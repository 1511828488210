// Imports => Utilities
import { AcUUID } from '@utils';

// Imports => Constants
import { KEYS, TITLES } from '@constants';

export const PickupSealbag = class {
	constructor(products) {
		this.products = products;

		return this.create();
	}

	create = () => {
		let coins = this.products
			.filter(product => product.subgroup === KEYS.COINBAGS)
			.map(product => ({ ...product, value: '' }));

		let notes = this.products
			.filter(product => product.subgroup === KEYS.NOTES)
			.map(product => ({ ...product, value: '' }));

		let result = {
			id: AcUUID(),
			title: TITLES.SEALBAG,
			type: KEYS.SEALBAGS,
			contents:
				notes && notes.length > 0
					? KEYS.NOTES
					: coins && coins.length > 0
					? KEYS.COINS
					: KEYS.NOTES,
			sealbag_number: '',
			coins,
			notes,
		};

		return result;
	};
};

export default PickupSealbag;
