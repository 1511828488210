export const ROLES = {
  USER: 'Gebruiker',
  BRANCH_MANAGER: 'Filiaalmanager',
  MANAGER: 'Manager',
  ANALYST: 'Analyst',
  ZIEMANN_PLANNER: 'Ziemann Planner',
  ZIEMANN_ADMIN: 'Ziemann Admin',
  ZIEMANN_ANALYST: 'Ziemann Analyst',
  ZIEMANN_FINANCE: 'Ziemann Finance',
};
