export const KEYS = {
	V1: 'V1',
	V2: 'V2',
	ACCESS_TOKEN: 'access_token',
	ACCOUNT: 'account',
	ACTIVE: 'active',
	ADD: 'add',
	ANNOUNCEMENTS: 'announcements',
	ASCENDING: 'ascending',
	ADMIN: 'admin',
	CART: 'cart',
	CASH: 'cash',
	CHANGE: 'change',
	COINBAGS: 'coin-bags',
	COINROLLS: 'coin-rolls',
	COUNT_DATE: 'count_date',
	COINS: 'coins',
	COMPANIES: 'companies',
	COMPANY: 'company',
	COMPANY_ID: 'company_id',
	COMPANY_ADMIN: 'company_admin',
	CONTENTS: 'contents',
	CREATED_AT: 'created_at',
	DELETE: 'delete',
	DELIVER_SEALBAGS: 'delivery',
	DELIVERY: 'delivery',
	DELIVERY_SLOTS: 'delivery_slots',
	DELIVERY_DATE: 'delivery_date',
	DESCENDING: 'descending',
	DATE_END: 'date_end',
	DATE_START: 'date_start',
	EMAIL: 'email',
	EMPHASIZED: 'emphasized',
	EXPIRES_AT: 'expires_at',
	EXPIRES_IN: 'expires_in',
	FEMALE: 'female',
	FIELDS: 'fields',
	FILTERS: 'filters',
	FOILBAGS: 'foil-bags',
	GROUP: 'group',
	GROUPS: 'groups',
	HOLIDAYS: 'holidays',
	INACTIVE: 'inactive',
	REPORT_DATE: 'report_date',
	INTERAL_REFERENCE: 'internal_reference',
	LOCALE: 'locale',
	LOCATION: 'location',
	LOCATIONS: 'locations',
	LOCATION_ID: 'location_id',
	LOCATION_CODE: 'location_code',
	MALE: 'male',
	MANAGER: 'manager',
	BRANCH_MANAGER: 'branch manager',
	ANALYST: 'analyst',
	META: 'meta',
	MODAL: 'modal',
	NAME: 'name',
	NEW_PASSWORD: 'new_password',
	NEW_PASSWORD_CONFIRMATION: 'new_password_confirmation',
	NEXT: 'next',
	NOTES: 'notes',
	OPTIONS: 'options',
	ORDER: 'order',
	ORDER_DATE: 'created_at',
	ORDER_NO: 'id',
	ORDER_ROWS: 'order_rows',
	ORDERS: 'orders',
	PAGINATION: 'pagination',
	PASSWORD: 'password',
	PENDING: 'pending',
	PICK_UP: 'pickup',
	PICKUP: 'pick-up',
	PICKUP_DELIVERY: 'pick-up-delivery',
	PICKUP_SEALBAGS: 'delivery',
	PREVIOUS: 'previous',
	PRODUCT: 'product',
	PRODUCTS: 'products',
	PROFILE: 'profile',
	PROFILE_CLONE: 'profile_clone',
	QUERY: 'query',
	REFRESH_TOKEN: 'refresh_token',
	REPORT: 'report',
	REPORTS: 'reports',
	ROLE: 'role',
	SCROLLER: 'ac-scroller',
	SEALBAG: 'sealbag',
	SEALBAG_NUMBER: 'sealbag_number',
	SEALBAGS: 'sealbags',
	SEEN: 'seen',
	SLOT: 'slot',
	SLOTS: 'slots',
	SORTBY: 'sortby',
	STAMPS: 'stamps',
	SUBTLE: 'subtle',
	SUPPORT_EMAIL_ADDRESS: 'salessupport@ziemann.nl',
	TYPE: 'type',
	TODAY: 'today',
	UPDATE: 'update',
	UNIT: 'unit',
	USER: 'user',
	USER_NAVIGATION: 'user_navigation',
	USERS: 'users',
	USER_ROLE: 'user_role',
	USERNAME: 'username',
	VISIBLE: 'visible',
	CURRENT_MONTH: 'current-month',
	PREVIOUS_MONTH: 'previous-month',
	CURRENT_QUARTER: 'current-quarter',
	CURRENT_WEEK: 'current-week',
};
