// Imports => Constants
import { ICONS, ROLES } from '@constants';
import { TITLES } from './titles.constants';

// Imports => Utilities
import { AcUUID } from '@utils';

// Imports => Views
import {
  AcActivate,
  AcAuthenticate,
  AcJumbo,
  AcMyAccount,
  AcNewOrder,
  AcNewPassword,
  AcOrderDetail,
  AcOrderOverview,
  AcUserOverview,
  AcReportsOverview,
  AcReportDetail,
  AcTransportSchedule,
  AcRecurringOrderOverview,
  AcNewRecurringOrder,
} from '@views';

export const PATHS = {
  AUTH: {
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    NEW_PASSWORD: '/reset-password',
    ACTIVATE: '/activate-account',
    JUMBO: {
      SPLASH: '/jumbo',
      RETRIEVE: '/jumbo/fetch',
    },
  },
  MY_ACCOUNT: '/my-account',
  NEW_ORDER: '/new-order',
  ORDER_DETAIL: '/order/:id',
  ORDER_OVERVIEW: '/orders',
  TRANSPORT_SCHEDULE: '/transport-schedule',
  USER_OVERVIEW: '/users',
  REPORTS_OVERVIEW: '/reports',
  REPORTS_DETAIL: '/reports/:type/:id',
  RECURRING_ORDER_OVERVIEW: '/recurring-orders',
  NEW_RECURRING_ORDER: '/new-recurring-order/',
};

export const ROUTES = {
  AUTHENTICATE: {
    id: AcUUID(),
    name: 'Authenticate',
    label: TITLES.LOGIN,
    path: PATHS.AUTH.LOGIN,
    component: AcAuthenticate,
    icon: null,
    roles: null,
    forbidden: false,
  },
  NEW_PASSWORD: {
    id: AcUUID(),
    name: 'NewPassword',
    label: TITLES.NEW_PASSWORD,
    path: PATHS.AUTH.NEW_PASSWORD,
    // reset-password?token=77649f7296da7f4c2fe81e1857a0ada31a918ca497a26ca5f5d01260e3e55676
    component: AcNewPassword,
    icon: null,
    roles: null,
    forbidden: false,
  },
  ACTIVATE: {
    id: AcUUID(),
    name: 'NewPassword',
    label: TITLES.ACTIVATE,
    path: PATHS.AUTH.ACTIVATE,
    // activate-account?email=user@acato.nl
    component: AcActivate,
    icon: null,
    roles: null,
    forbidden: false,
  },
  JUMBO_SPLASH: {
    id: AcUUID(),
    name: 'JumboSplash',
    label: TITLES.ACTIVATE,
    path: PATHS.AUTH.JUMBO.SPLASH,
    component: AcJumbo,
    icon: null,
    roles: null,
    forbidden: false,
  },
  JUMBO_RETRIEVE: {
    id: AcUUID(),
    name: 'JumboRetrieve',
    label: TITLES.ACTIVATE,
    path: PATHS.AUTH.JUMBO.RETRIEVE,
    component: AcJumbo,
    icon: null,
    roles: null,
    forbidden: false,
  },
  MY_ACCOUNT: {
    id: AcUUID(),
    name: 'MyAccount',
    label: TITLES.MY_ACCOUNT,
    path: PATHS.MY_ACCOUNT,
    component: AcMyAccount,
    icon: ICONS.ACCOUNT_CIRCLE_OUTLINE,
    roles: [
      ROLES.USER,
      ROLES.BRANCH_MANAGER,
      ROLES.MANAGER,
      ROLES.ANALYST,
      ROLES.ZIEMANN_ADMIN,
      ROLES.ZIEMANN_PLANNER,
      ROLES.ZIEMANN_ANALYST,
      ROLES.ZIEMANN_FINANCE,
    ],
    forbidden: true,
  },
  NEW_ORDER: {
    id: AcUUID(),
    name: 'NewOrder',
    label: TITLES.NEW_ORDER,
    path: PATHS.NEW_ORDER,
    component: AcNewOrder,
    icon: null,
    roles: [ROLES.MANAGER, ROLES.BRANCH_MANAGER, ROLES.USER],
    forbidden: true,
  },
  ORDER_DETAIL: {
    id: AcUUID(),
    name: 'OrderDetail',
    label: TITLES.ORDER_DETAILS,
    path: PATHS.ORDER_DETAIL,
    component: AcOrderDetail,
    icon: null,
    roles: [
      ROLES.USER,
      ROLES.BRANCH_MANAGER,
      ROLES.MANAGER,
      ROLES.ANALYST,
      ROLES.ZIEMANN_ANALYST,
      ROLES.ZIEMANN_FINANCE,
      ROLES.ZIEMANN_ADMIN,
    ],
    forbidden: true,
  },
  ORDER_OVERVIEW: {
    id: AcUUID(),
    name: 'OrderOverview',
    label: TITLES.ORDER_OVERVIEW,
    path: PATHS.ORDER_OVERVIEW,
    component: AcOrderOverview,
    icon: null,
    roles: [
      ROLES.USER,
      ROLES.BRANCH_MANAGER,
      ROLES.MANAGER,
      ROLES.ANALYST,
      ROLES.ZIEMANN_ANALYST,
      ROLES.ZIEMANN_FINANCE,
      ROLES.ZIEMANN_ADMIN,
    ],
    forbidden: true,
  },
  USER_OVERVIEW: {
    id: AcUUID(),
    name: 'UserOverview',
    label: TITLES.USER_OVERVIEW,
    path: PATHS.USER_OVERVIEW,
    component: AcUserOverview,
    icon: null,
    roles: [
      ROLES.BRANCH_MANAGER,
      ROLES.MANAGER,
      ROLES.ZIEMANN_ADMIN,
      ROLES.ZIEMANN_ANALYST,
    ],
    forbidden: true,
  },

  NEW_RECURRING_ORDER: {
    id: AcUUID(),
    name: 'NewRecurringOrder',
    label: TITLES.NEW_RECURRING_ORDER,
    path: PATHS.NEW_RECURRING_ORDER,
    component: AcNewRecurringOrder,
    icon: null,
    roles: [ROLES.MANAGER, ROLES.ZIEMANN_ADMIN, ROLES.ZIEMANN_ANALYST],
    permissions: 'Petty cash leveringen',
    forbidden: true,
  },
  RECURRING_ORDER_OVERVIEW: {
    id: AcUUID(),
    name: 'RecurringOrderOverview',
    label: TITLES.RECURRING_ORDER_OVERVIEW,
    path: PATHS.RECURRING_ORDER_OVERVIEW,
    component: AcRecurringOrderOverview,
    icon: null,
    roles: [ROLES.MANAGER, ROLES.ZIEMANN_ADMIN, ROLES.ZIEMANN_ANALYST],
    permissions: 'Petty cash leveringen',
    forbidden: true,
  },
  REPORTS_DETAIL: {
    id: AcUUID(),
    name: 'ReportsDetail',
    label: TITLES.REPORTS_DETAIL,
    path: PATHS.REPORTS_DETAIL,
    component: AcReportDetail,
    icon: null,
    roles: [
      ROLES.USER,
      ROLES.BRANCH_MANAGER,
      ROLES.MANAGER,
      ROLES.ANALYST,
      ROLES.ZIEMANN_ANALYST,
      ROLES.ZIEMANN_FINANCE,
      ROLES.ZIEMANN_ADMIN,
    ],
    forbidden: true,
  },
  REPORTS_OVERVIEW: {
    id: AcUUID(),
    name: 'ReportsOverview',
    label: TITLES.REPORTS_OVERVIEW,
    path: PATHS.REPORTS_OVERVIEW,
    component: AcReportsOverview,
    icon: null,
    roles: [
      ROLES.USER,
      ROLES.BRANCH_MANAGER,
      ROLES.MANAGER,
      ROLES.ANALYST,
      ROLES.ZIEMANN_ANALYST,
      ROLES.ZIEMANN_FINANCE,
      ROLES.ZIEMANN_ADMIN,
    ],
    forbidden: true,
  },
  TRANSPORT_SCHEDULE: {
    id: AcUUID(),
    name: 'TransportSchedule',
    label: TITLES.TRANSPORT_SCHEDULE,
    path: PATHS.TRANSPORT_SCHEDULE,
    component: AcTransportSchedule,
    icon: null,
    roles: [
      ROLES.USER,
      ROLES.BRANCH_MANAGER,
      ROLES.MANAGER,
      ROLES.ANALYST,
      ROLES.ZIEMANN_ANALYST,
      ROLES.ZIEMANN_FINANCE,
      ROLES.ZIEMANN_ADMIN,
      ROLES.ZIEMANN_PLANNER,
    ],
    forbidden: true,
  },
};

export const NAVIGATION_ITEMS = [
  ROUTES.TRANSPORT_SCHEDULE,
  ROUTES.ORDER_OVERVIEW,
  ROUTES.RECURRING_ORDER_OVERVIEW,
  ROUTES.REPORTS_OVERVIEW,
  ROUTES.USER_OVERVIEW,
];

export const USER_NAVIGATION_ITEMS = [ROUTES.MY_ACCOUNT];

export const DEFAULT_ROUTE = ROUTES.TRANSPORT_SCHEDULE;
export const REDIRECT_ROUTE = ROUTES.AUTHENTICATE;
