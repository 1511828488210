// Imports => MOBX
import { observable, action } from 'mobx';

// Imports => Constants
import { KEYS, MIMETYPES } from '@constants';

// Imports => Utilities
import {
	AcDownloadFile,
	AcIsSet,
	AcIsString,
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcFormatErrorMessage,
	AcFormatErrorCode,
} from '@utils';

const _default = {
	options: {},
	meta: {},
	order: null,
	orders: [],
	filters: {},
	sortby: {
		field: KEYS.DELIVERY_DATE,
		direction: KEYS.DESCENDING,
	},
};

let app = {};

export class OrdersStore {
	constructor(store) {
		AcAutoLoad(this, KEYS.ORDER);
		AcAutoLoad(this, KEYS.ORDERS);
		AcAutoLoad(this, KEYS.OPTIONS);
		AcAutoLoad(this, KEYS.META);
		AcAutoLoad(this, KEYS.FILTERS);
		AcAutoLoad(this, KEYS.SORTBY);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	order = null;

	@observable
	orders = [];

	@observable
	options = {};

	@observable
	sortby = {
		field: KEYS.CREATED_AT,
		direction: KEYS.DESCENDING,
	};

	@observable
	query = '';

	@observable
	meta = {};

	@observable
	filters = {};

	@observable
	created_at = {
		start: null,
		end: null,
	};

	@observable
	delivery_date = {
		start: null,
		end: null,
	};

	@observable
	loading = {
		status: false,
		message: null,
	};

	@action
	setLoading(state, message) {
		this.loading = {
			status: state || false,
			message: message || 'Sorting your orders',
		};
	}

	@action
	setSortBy(field) {
		let sortby = {
			field,
			direction: this.sortby.direction,
		};
		if (this.sortby.field === field) {
			sortby.direction =
				sortby.direction === KEYS.ASCENDING ? KEYS.DESCENDING : KEYS.ASCENDING;
		} else if (this.sortby.field !== field) {
			sortby.direction = KEYS.DESCENDING;
		}

		this.set(KEYS.SORTBY, sortby);
	}

	@action
	getSortBy() {
		let result = this.sortby.field;
		result = this.sortby.direction === KEYS.ASCENDING ? result : `-${result}`;
		return result;
	}

	@action
	resetSortBy() {
		this.set(KEYS.SORTBY, _default.sortby);
	}

	@action
	setFilters = (name, value) => {
		if (!AcIsSet(name)) return;
		if (!AcIsSet(KEYS.FILTERS)) return;

		const filters = this.filters;

		this.set(KEYS.FILTERS, { ...filters, [name]: value });
	};

	@action
	getFilters = () => {
		Object.keys(this.filters).forEach(key => {
			let item = this.filters[key];

			// Trim leading or trailing spaces in the filter value
			if (AcIsString(item)) {
				item = item.replace(/^\s+|\s+$/g, '');
				this.filters[key] = item;
			}
		});

		return this.filters;
	};

	@action
	resetFilters = () => {
		this.set(KEYS.FILTERS, _default.filters);
	};

	@action
	all() {
		this.setLoading(true);

		return app.store.api.orders
			.get()
			.then(response => {
				this.set(KEYS.ORDERS, response.data);

				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				this.setLoading(false);
				throw error;
			});
	}

	@action
	all_paginated(page) {
		this.setLoading(true);

		const _current_page = this.meta.current_page;
		const _page = AcIsSet(page) ? page : AcIsSet(_current_page) ? _current_page : 1;
		const _sort = this.getSortBy();
		const _filters = this.getFilters();

		return app.store.api.orders
			.get_paginated(_page, _sort, _filters)
			.then(response => {
				this.set(KEYS.ORDERS, response.data);
				this.set(KEYS.META, { ...response.meta, ...response.links });

				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				this.setLoading(false);
				throw error;
			});
	}

	@action
	get_by_id(id) {
		this.setLoading(true);

		return app.store.api.orders
			.get_by_id(id)
			.then(response => {
				this.set(KEYS.ORDER, response.data);

				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				this.setLoading(false);
				throw error;
			});
	}

	@action
	download_specifications(order_id) {
		this.setLoading(true, 'Plotting specifications...');

		return app.store.api.orders
			.download(order_id)
			.then(response => {
				this.setLoading(false);

				AcDownloadFile(
					response.data,
					`ziemann-order-${order_id}-deposit-briefs.pdf`,
					MIMETYPES.PDF
				);

				return response;
			})
			.catch(error => {
				this.setLoading(false);
				throw error;
			});
	}

	download_specification(order_id, spec_id, sb_num = 1) {
		this.setLoading(true, 'Plotting specifications...');

		return app.store.api.orders
			.download_specification(order_id, spec_id)
			.then(response => {
				this.setLoading(false);

				AcDownloadFile(
					response.data,
					`ziemann-order-${order_id}-${spec_id}-deposit-briefs-${sb_num}.pdf`,
					MIMETYPES.PDF
				);

				return response;
			})
			.catch(error => {
				this.setLoading(false);
				throw error;
			});
	}

	@action
	delete = id => {
		this.setLoading(true);

		return app.store.api.orders
			.delete(id)
			.then(async response => {
				await this.all_paginated();
				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				this.setLoading(false);
				throw error;
			});
	};

	@action
	set(target, value, save = false) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;
		if (typeof value === 'undefined' || value === null) return;

		this[target] = value;
		if (save) AcSaveState(target, this[target]);
	}

	@action
	setValue(target, property, value) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;
		if (!property) return;
		if (typeof value === 'undefined' || value === null) return;

		this[target][property] = value;
		AcSaveState(target, this[target]);
	}

	@action
	reset(target) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;

		this[target] = _default[target];
		AcSaveState(target, this[target]);
	}
}

export default OrdersStore;
