// Imports => MOBX
import { observable, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcIsSet,
	AcIsString,
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
} from '@utils';

const _default = {
	options: {},
	meta: {},
	user: {},
	users: [],
	filters: {},
	sortby: {
		field: KEYS.NAME,
		direction: KEYS.ASCENDING,
	},
};

let app = {};

export class UsersStore {
	constructor(store) {
		AcAutoLoad(this, KEYS.USER);
		AcAutoLoad(this, KEYS.USERS);
		AcAutoLoad(this, KEYS.META);
		AcAutoLoad(this, KEYS.FILTERS);
		AcAutoLoad(this, KEYS.SORTBY);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	user = {};

	@observable
	users = [];

	@observable
	meta = {};

	@observable
	sortby = {
		field: KEYS.NAME,
		direction: KEYS.ASCENDING,
	};

	@observable
	filters = {};

	@observable
	loading = {
		status: false,
		message: null,
	};

	@action
	setLoading(state, message) {
		this.loading = {
			status: state || false,
			message: message || 'Gathering users',
		};
	}

	@action
	setSortBy(field) {
		let sortby = {
			field,
			direction: this.sortby.direction,
		};

		if (this.sortby.field === field) {
			sortby.direction =
				sortby.direction === KEYS.ASCENDING ? KEYS.DESCENDING : KEYS.ASCENDING;
		} else if (this.sortby.field !== field) {
			sortby.direction = KEYS.DESCENDING;
		}

		this.set(KEYS.SORTBY, sortby);
	}

	@action
	getSortBy() {
		let result = this.sortby.field;
		result = this.sortby.direction === KEYS.ASCENDING ? result : `-${result}`;
		return result;
	}

	@action
	resetSortBy() {
		this.set(KEYS.SORTBY, _default.sortby);
	}

	@action
	setFilters = (name, value) => {
		if (!AcIsSet(name)) return;
		if (!AcIsSet(KEYS.FILTERS)) return;

		const filters = this.filters;

		this.set(KEYS.FILTERS, { ...filters, [name]: value });
	};

	@action
	getFilters = () => {
		Object.keys(this.filters).forEach(key => {
			let item = this.filters[key];

			// Trim leading or trailing spaces in the filter value
			if (AcIsString(item)) {
				item = item.replace(/^\s+|\s+$/g, '');
				this.filters[key] = item;
			}
		});

		return this.filters;
	};

	@action
	resetFilters = () => {
		this.set(KEYS.FILTERS, _default.filters);
	};

	@action
	all() {
		this.setLoading(true);

		return app.store.api.users
			.get()
			.then(response => {
				this.set(KEYS.USERS, response.data);

				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: 'Failed to load users',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	}

	@action
	all_paginated(page) {
		this.setLoading(true);

		const _current_page = this.meta.current_page;
		const _page = AcIsSet(page)
			? page
			: AcIsSet(_current_page)
			? _current_page
			: 1;
		const _sort = this.getSortBy();
		const _filters = this.getFilters();

		return app.store.api.users
			.get_paginated(_page, _sort, _filters)
			.then(response => {
				this.set(KEYS.USERS, response.data);
				this.set(KEYS.META, { ...response.meta, ...response.links });

				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: 'Failed to load users',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	}

	@action
	get_by_id(id) {
		this.setLoading(true);

		return app.store.api.users
			.get_by_id(id)
			.then(response => {
				this.set(KEYS.USER, response.data);

				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: 'Failed to load user',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	}

	@action
	add(_data) {
		this.setLoading(true, 'Storing the new user');

		return app.store.api.users
			.store(_data)
			.then(response => {
				app.store.toasters.add({
					variant: 'success',
					title: {
						string: 'New user {{name}} has been added',
						options: {
							name: _data.name,
						},
					},
				});

				this.all();

				return response.data;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: {
						string: 'Failed to add user {{name}}',
						options: {
							name: _data.name,
						},
					},
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});
				this.setLoading(false);
				throw error;
			});
	}

	@action
	update(_id, _data) {
		this.setLoading(true, 'Storing the new user');

		return app.store.api.users
			.update(_id, _data)
			.then(response => {
				app.store.toasters.add({
					variant: 'success',
					title: {
						string: 'User {{name}} is updated',
						options: {
							name: _data.name,
						},
					},
				});

				this.all();

				return response.data;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: {
						string: 'Failed to update user {{name}}',
						options: {
							name: _data.name,
						},
					},
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});
				this.setLoading(false);
				throw error;
			});
	}

	@action
	delete(_id, _name) {
		this.setLoading(true, 'Deleting user');

		return app.store.api.users
			.delete(_id)
			.then(response => {
				app.store.toasters.add({
					variant: 'success',
					title: {
						string: `Successfully deleted user {{name}}`,
						options: {
							name: _name,
						},
					},
				});

				this.all();

				return response;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: {
						string: `Failed to delete user {{name}}`,
						options: {
							name: _name,
						},
					},
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});
				this.setLoading(false);
				throw error;
			});
	}

	@action
	resend_invite(_id, _name) {
		this.setLoading(true, 'Preparing the invitation');

		return app.store.api.users
			.resend_invite(_id)
			.then(response => {
				app.store.toasters.add({
					variant: 'success',
					title: {
						string: 'Successfully (re)send an invitation to user {{name}}',
						options: {
							name: _name,
						},
					},
				});

				this.all();

				return response;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: {
						string: 'Failed to (re)send an invitation to user {{name}}',
						options: {
							name: _name,
						},
					},
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});
				this.setLoading(false);
				throw error;
			});
	}

	@action
	set(target, value, save = false) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;
		if (typeof value === 'undefined' || value === null) return;

		this[target] = value;
		if (save) AcSaveState(target, this[target]);
	}

	@action
	setValue(target, property, value) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;
		if (!property) return;
		if (typeof value === 'undefined' || value === null) return;

		this[target][property] = value;
		AcSaveState(target, this[target]);
	}

	@action
	reset(target) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;

		this[target] = _default[target];
		AcSaveState(target, this[target]);
	}
}

export default UsersStore;
