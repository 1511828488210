import qs from 'qs';

// Imports => Constants
import { ENDPOINTS } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

export class ReportsAPI {
	constructor(Instance) {
		this.Client = Instance.Client;
		this.DownloadClient = Instance.DownloadClient;
		this.Store = Instance.Store;
	}

	get = () => {
		return this.Client.get(
			ENDPOINTS(this.Store.auth.current_role).REPORTS.NOTES.ALL,
			{
				params: { options: true },
			}
		).then(response => response.data);
	};

	get_paginated = (_page, _sort, _filters, _type) => {
		let params = {
			page: _page,
			sort: _sort,
			..._filters,
		};

		if (_filters.query) {
			params.seal_bag_number = _filters.query;
			delete params.query;
		}

		delete params.report_date;

		return this.Client.get(
			ENDPOINTS(this.Store.auth.current_role).REPORTS[_type.toUpperCase()].ALL,
			{
				params,
			}
		).then(response => response.data);
	};

	get_by_id = (report_id, _type, _filters) => {
		return this.Client.get(
			ENDPOINTS(this.Store.auth.current_role).REPORTS[_type.toUpperCase()].GET_BY_ID(
				report_id
			)
		).then(response => response.data);
	};

	download_overview_report = (collection, type, format, _filters) => {
		console.log(collection, type, format, _filters);
		const params = { ..._filters, format, locations: collection };

		delete params.report_date;
		delete params.query;

		return this.DownloadClient.get(
			ENDPOINTS(this.Store.auth.current_role).REPORTS[type.toUpperCase()]
				.DOWNLOAD_OVERVIEW_REPORT,
			{
				params,
			}
		).then(response => response);
	};

	download_report = (report_id, type, format) => {
		return this.DownloadClient.get(
			ENDPOINTS(this.Store.auth.current_role).REPORTS[
				type.toUpperCase()
			].DOWNLOAD_REPORT(report_id),
			{
				params: {
					format,
				},
			}
		).then(response => response);
	};
}

export default ReportsAPI;
