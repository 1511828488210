// Imports => React
import React from 'react';
import { withNamespaces } from 'react-i18next';
import clsx from 'clsx';

// Imports => Data
import { KEYBOARD_SHORTCUTS } from '@data/keyboard.data';

// Imports => Utilities
import { AcUUID } from '@utils';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';

const _CLASSES = {
	MAIN: 'ac-modal-keyboard-shortcuts',
	GROUP: 'ac-modal-keyboard-shortcuts__group',
	TITLE: 'ac-modal-keyboard-shortcuts__title',
	LIST: {
		MAIN: 'ac-modal-keyboard-shortcuts__list',
		ITEM: 'ac-modal-keyboard-shortcuts__list__item',
		LABEL: 'ac-modal-keyboard-shortcuts__list__item-label',
		KEY: 'ac-modal-keyboard-shortcuts__list__item-key',
	},
};

const AcModalKeyboardShortcuts = ({ role, t }) => {
	const $modal = React.createRef();
	const data = Object.keys(KEYBOARD_SHORTCUTS)
		.map(key => KEYBOARD_SHORTCUTS[key])
		.filter(item => {
			if (item.restricted) return item.restricted.indexOf(role) > -1;
			return true;
		});

	const getListKeyClassNames = () => {
		return clsx(_CLASSES.LIST.KEY);
	};

	const getListLabelClassNames = () => {
		return clsx(_CLASSES.LIST.LABEL);
	};

	const getListItemClassNames = () => {
		return clsx(_CLASSES.LIST.ITEM);
	};

	const getListClassNames = () => {
		return clsx(_CLASSES.LIST.MAIN);
	};

	const getGroupTitleClassNames = () => {
		return clsx(_CLASSES.TITLE);
	};

	const getGroupClassNames = () => {
		return clsx(_CLASSES.GROUP);
	};

	const getStyleClassNames = () => {
		return clsx(_CLASSES.MAIN);
	};

	return (
		<div className={getStyleClassNames()} ref={$modal}>
			<AcRow>
				{data &&
					data.map(group => {
						return (
							<AcColumn
								xs={12}
								sm={12}
								md={6}
								key={`ac-keyboard-shortcut-group-${AcUUID()}`}
							>
								<div className={getGroupClassNames()}>
									<AcHeading rank={5} className={getGroupTitleClassNames()}>
										{group.title}
									</AcHeading>
									<ul className={getListClassNames()}>
										{group.items &&
											group.items.map(item => {
												return (
													<li
														key={`ac-keyboard-shortcut-${AcUUID()}`}
														className={getListItemClassNames()}
													>
														<div className={getListLabelClassNames()}>
															{item.label}
														</div>
														<div className={getListKeyClassNames()}>
															<span>{item.key}</span>
														</div>
													</li>
												);
											})}
									</ul>
								</div>
							</AcColumn>
						);
					})}
			</AcRow>
		</div>
	);
};

export default withNamespaces()(React.memo(AcModalKeyboardShortcuts));
