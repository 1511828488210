// Imports => MOBX
import { observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcIsUndefined,
	AcIsSet,
	AcIsArray,
	AcFormatErrorMessage,
	AcFormatErrorCode,
} from '@utils';

let app = {};

export class AnnouncementsStore {
	constructor(store) {
		AcAutoLoad(this, KEYS.ANNOUNCEMENTS);
		AcAutoLoad(this, KEYS.SEEN);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	announcements = [];

	@observable
	seen = [];

	@observable
	loading = false;

	@computed
	get latest() {
		return this.getUnseenItem;
	}

	@computed
	get seen_items() {
		return this.seen;
	}

	@computed
	get all_items() {
		return this.announcements;
	}

	@computed
	get getUnseenItem() {
		let collection = this.all_items;
		let seen = this.seen_items;
		let result = null;

		if (!AcIsArray(seen)) this.set(KEYS.SEEN, []);

		if (collection && collection.length) {
			if (!seen) {
				result = collection[0];
			} else {
				let n = 0;
				let len = collection.length;

				for (n; n < len; n++) {
					const item = collection[n];
					if (seen.indexOf(item.id) === -1) {
						result = item;
						break;
					}
				}
			}
		}

		return result;
	}

	@action
	setLoading = state => {
		this.loading = state ? state : false;
	};

	@action
	all = () => {
		this.setLoading(true);

		return app.store.api.announcements
			.get()
			.then(async response => {
				await this.set(KEYS.ANNOUNCEMENTS, response.data);

				this.setLoading(false);
				return response.data;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: 'Laden van mededelingen is niet gelukt',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	@action
	close = id => {
		let seen = this.seen;

		if (!seen) seen = [];

		seen.push(id);

		this.set(KEYS.SEEN, seen);
	};

	@action
	set = (target, value) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (AcIsUndefined(value)) return;

		this[target] = value;
		AcSaveState(target, this[target]);
	};
}

export default AnnouncementsStore;
