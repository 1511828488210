// Imports => React
import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { inject, observer } from 'mobx-react';

// Imports => Controller
import AcNavigationController from './ac-navigation.controller.js';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web.js';

@inject('routing', 'store')
@observer
class AcNavigation extends AcNavigationController {
	render() {
		const { indicate, t } = this.props;
		const { current_role } = this.store.auth;
		const { current_permissions } = this.store.profile;
		const { indicator, routes } = this.state;

		const _routes = routes
			.filter(route => {
				if (!route.roles) return true;

				return route.roles.indexOf(current_role) > -1;
			})
			.filter(route => {
				if (!route.permissions) return true;
				else if (
					route.permissions &&
					current_permissions.indexOf(route.permissions) > -1
				) {
					return true;
				}

				return false;
			});

		return (
			<nav
				ref={node => (this.state.el = node)}
				className={this.getStyleClassNames()}
			>
				<ul className={this.getNavListClassNames()}>
					{_routes &&
						_routes.map(route => {
							return (
								<li
									key={route.id}
									ref={node => (route.ref = node)}
									className={this.getNavListItemClassNames()}
								>
									{route.path && (
										<NavLink
											to={route.path}
											onClick={event => {
												this.handleClickEvent(event, route);
											}}
											className={this.getNavLinkClassNames(route)}
											activeClassName={this.getNavLinkClassNames(route, 'active')}
											isActive={this.isActive}
											title={t(route.title)}
											exact
											strict
										>
											{typeof route.label === 'string' && t(route.label)}
											{typeof route.label !== 'string' && route.label}
											<AcRipple size={'small'} simple />
										</NavLink>
									)}
									{!route.path && (
										<span
											onClick={event => {
												this.handleClickEvent(event, route);
											}}
											className={this.getNavLinkClassNames(route)}
										>
											{typeof route.label === 'string' && t(route.label)}
											{typeof route.label !== 'string' && route.label}
											<AcRipple size={'small'} simple />
										</span>
									)}
								</li>
							);
						})}
				</ul>
				{indicate && (
					<span
						ref={node => (indicator.el = node)}
						className={this.getIndicatorClassNames()}
						style={indicator.position}
					/>
				)}
			</nav>
		);
	}
}

export default withNamespaces()(withRouter(AcNavigation));
