// Imports => React
import React, { useMemo, memo } from 'react';
import clsx from 'clsx';

const _CLASSES = {
	MAIN: 'ac-header',
	LIGHT: 'ac-header--light',
	DARK: 'ac-header--dark',
	ALPHA: 'ac-header--alpha',
	TRANSPARENT: 'ac-header--transparent',
	CONTAINER: {
		MAIN: 'ac-header__container',
	},
};

const AcHeader = ({ theme = 'default', children }) => {
	const getContainerClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTAINER.MAIN]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN, theme && _CLASSES[theme.toUpperCase()]]);
	}, []);

	return (
		<header className={getStyleClassNames} id={getStyleClassNames}>
			<div className={getContainerClassNames}>{children}</div>
		</header>
	);
};

export default memo(AcHeader);
