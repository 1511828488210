// Imports => React
import React from 'react';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, ROLES, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

const _CLASSES = {
	MAIN: 'ac-modal-contact-form',
	TITLE: 'ac-modal-contact-form__title',
	BODY: 'ac-modal-contact-form__body',
};

class AcModalContactFormController extends React.Component {
	constructor(props) {
		super(props);

		this.store = props.store;
		this.$modal = React.createRef();
	}

	componentDidMount() {
		this.init();
	}

	init = async () => {
		if (
			this.store.auth.current_role === ROLES.MANAGER ||
			this.store.auth.current_role === ROLES.ANALYST
		) {
			await this.store.locations.all();
			this.store.contact.reset(KEYS.FIELDS);
		}
	};

	handleChange = (event, name, value) => {
		if (event && event.persist) event.persist();
		if (!name || !AcIsSet(this.store.contact.fields[name])) return;

		this.store.contact.setValue(KEYS.FIELDS, name, value);
	};

	handleSubmit = async event => {
		if (event && event.preventDefault) event.preventDefault();
		if (this.hasError()) return;

		await this.store.contact.send().then(() => {
			this.store.contact.reset(KEYS.FIELDS);
			this.store.ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
		});
	};

	hasError = () => {
		let error = undefined;

		const { fields } = this.store.contact;

		for (let key in fields) {
			const field = fields[key];
			const required = field.required;
			const valid = field.valid;
			const value = field.value;

			if (required && !valid) {
				error = key;
				break;
			} else if (!required && AcIsSet(value) && value !== '' && !valid) {
				error = key;
				break;
			}
		}

		if (error === undefined) {
			return false;
		}

		return true;
	};

	getSubjectInputOptions = () => {
		const {
			fields: { subject },
		} = this.store.contact;
		const { t } = this.props;

		return {
			type: TYPES.TEXT,
			value: subject.value,
			name: 'subject',
			label: t('Subject'),
			required: subject.required,
			callback: this.handleChange,
			tabIndex: 1,
		};
	};

	getContentInputOptions = () => {
		const {
			fields: { content },
		} = this.store.contact;
		const { t } = this.props;

		return {
			type: TYPES.TEXTAREA,
			value: content.value,
			name: 'content',
			label: t('Message'),
			required: content.required,
			callback: this.handleChange,
			tabIndex: 2,
		};
	};

	getLocationInputOptions = () => {
		const { locations } = this.store.locations;
		const {
			fields: { location_id },
		} = this.store.contact;
		const { t } = this.props;

		const options = locations.map(loc => ({
			label: loc.name,
			value: loc.id,
		}));

		return {
			value: location_id.value,
			name: 'location_id',
			label: t('Location'),
			placeholder: t('Choose a location'),
			search: true,
			options,
			maxOptions: 5,
			required: location_id.required,
			callback: this.handleChange,
			tabIndex: 3,
		};
	};

	getSubmitButtonOptions = () => {
		return {
			theme: THEMES.ALPHA,
			disabled: this.hasError(),
			type: TYPES.SUBMIT,
			callback: this.handleSubmit,
			tabIndex: 7,
		};
	};

	getCancelButtonOptions = () => {
		return {
			theme: THEMES.ALPHA,
			variant: VARIANTS.TRANSPARENT,
			callback: () => {
				this.store.ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
			},
		};
	};

	getBodyClassNames() {
		return clsx(_CLASSES.BODY);
	}

	getTitleClassNames() {
		return clsx(_CLASSES.TITLE);
	}

	getStyleClassNames() {
		return clsx(_CLASSES.MAIN);
	}
}

AcModalContactFormController.propTypes = {};

AcModalContactFormController.defaultProps = {};

export default AcModalContactFormController;
