import qs from 'qs';

// Imports => Constants
import { ENDPOINTS } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

export class OrdersAPI {
	constructor(Instance) {
		this.Client = Instance.Client;
		this.DownloadClient = Instance.DownloadClient;
		this.Store = Instance.Store;
	}

	get = () => {
		return this.Client.get(
			ENDPOINTS(this.Store.auth.current_role).RECURRING_ORDERS.ALL
		).then(response => response.data);
	};

	get_paginated = (_page, _sort, _filters) => {
		let params = {
			paginated: true,
			page: _page,
			sort: _sort,
		};

		if (_filters && Object.keys(_filters)) {
			params.filter = {};
			Object.keys(_filters)
				.filter(key => AcIsSet(_filters[key]))
				.forEach(key => {
					params.filter[key] = _filters[key];
				});
		}

		return this.Client.get(
			ENDPOINTS(this.Store.auth.current_role).RECURRING_ORDERS.ALL,
			{
				params,
			}
		).then(response => response.data);
	};

	get_by_id = _order_id => {
		return this.Client.get(
			ENDPOINTS(this.Store.auth.current_role).ORDERS.GET_BY_ID(_order_id)
		).then(response => response.data);
	};

	create = () => {
		return this.Client.post(
			ENDPOINTS(this.Store.auth.current_role).ORDERS.CREATE
		).then(response => response.data);
	};

	store = _data => {
		return this.Client.post(
			ENDPOINTS(this.Store.auth.current_role).RECURRING_ORDERS.STORE,
			_data
		).then(response => response.data);
	};

	update = (_id, _data) => {
		return this.Client.patch(
			ENDPOINTS(this.Store.auth.current_role).RECURRING_ORDERS.UPDATE(_id),
			_data
		).then(response => response.data);
	};

	delete = _id => {
		return this.Client.delete(
			ENDPOINTS(this.Store.auth.current_role).RECURRING_ORDERS.DELETE(_id)
		).then(response => response.data);
	};
}

export default OrdersAPI;
