// Imports => React
import React from 'react';
import clsx from 'clsx';
import Rollbar from 'rollbar';

// Imports => Constants
import { ENVIRONMENTS } from '@constants';

// Imports => Config
import config from '@config';

const _CLASSES = {
	MAIN: 'ac-error-boundary',
};

class AcErrorBoundaryController extends React.PureComponent {
	constructor(props) {
		super(props);

		if (config.rollbar.payload.environment === ENVIRONMENTS.PRODUCTION) {
			this.rollbar = new Rollbar(config.rollbar);
		}
	}

	componentDidCatch(error, info) {
		if (config.rollbar.payload.environment !== ENVIRONMENTS.PRODUCTION) return;
		this.logError(error);
	}

	logInfo = info => {
		// Do not log info messages during development or any other environment then production
		if (config.rollbar.payload.environment !== ENVIRONMENTS.PRODUCTION) return;

		// Example log event using the rollbar object.
		this.rollbar.info(info || 'example info message from ac-error-boundary');
	};

	logError = error => {
		// Do not log error messages during development or any other environment then production
		if (config.rollbar.payload.environment !== ENVIRONMENTS.PRODUCTION) return;

		// Example error, which will be reported to rollbar.
		this.rollbar.error(error || 'example error from ac-error-boundary');
	};

	getStyleClassNames() {
		return clsx(_CLASSES.MAIN);
	}
}

export default AcErrorBoundaryController;
