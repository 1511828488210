import API from '@api';

import AuthStore from '@stores/auth.store';
import AnnouncementsStore from '@stores/announcements.store';
import ContactStore from '@stores/contact.store';
import ProfileStore from '@stores/profile.store';
import SettingsStore from '@stores/settings.store';

import CompaniesStore from '@stores/companies.store';
import LocationsStore from '@stores/locations.store';
import OrdersStore from '@stores/orders.store';
import OrderStore from '@stores/order.store';
import ProductsStore from '@stores/products.store';
import RecurringOrdersStore from '@stores/recurring-orders.store';
import ReportsStore from '@stores/reports.store';
import SlotsStore from '@stores/slots.store';
import UsersStore from '@stores/users.store';

import UiStore from '@stores/ui.store';
import ToastersStore from '@stores/toasters.store';

class Store {
	constructor(config) {
		this.config = config;

		this.api = new API(config, this);

		this.auth = new AuthStore(this);
		this.announcements = new AnnouncementsStore(this);
		this.contact = new ContactStore(this);
		this.profile = new ProfileStore(this);
		this.settings = new SettingsStore(this);

		this.companies = new CompaniesStore(this);
		this.locations = new LocationsStore(this);
		this.orders = new OrdersStore(this);
		this.order = new OrderStore(this);
		this.products = new ProductsStore(this);
		this.recurring_orders = new RecurringOrdersStore(this);
		this.reports = new ReportsStore(this);
		this.slots = new SlotsStore(this);
		this.users = new UsersStore(this);

		this.ui = new UiStore(this);
		this.toasters = new ToastersStore(this);
	}
}

export default Store;
