// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Constants
import { LOADER, ROLES, SIZES, THEMES } from '@constants';

// Imports => Controller
import AcModalContactFormController from './ac-modal-contact-form.controller';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

@inject('store')
@observer
class AcModalContactForm extends AcModalContactFormController {
	render() {
		const { t } = this.props;
		const { loading } = this.store.contact;
		const { current_role } = this.store.auth;

		return (
			<div className={this.getStyleClassNames()} ref={this.$modal}>
				<div className={this.getBodyClassNames()}>
					<AcContainer fluid={true}>
						<AcRow className={'h-margin-bottom-10'}>
							<AcColumn xs={12} sm={6}>
								<AcTextInput {...this.getSubjectInputOptions()} />
							</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-bottom-10'}>
							<AcColumn>
								<AcTextInput {...this.getContentInputOptions()} />
							</AcColumn>
						</AcRow>

						{current_role !== ROLES.USER && (
							<AcRow className={'h-margin-bottom-10'}>
								<AcColumn>
									<AcSelectBox {...this.getLocationInputOptions()} />
								</AcColumn>
							</AcRow>
						)}

						<AcRow className={'h-margin-top-45'}>
							<AcColumn xs={12} sm={12} md={6} className={'h-text--align-left'}>
								<AcButton {...this.getCancelButtonOptions()}>
									{t('Cancel')}
									<AcRipple theme={THEMES.LIGHT} size={SIZES.SMALL} simple />
								</AcButton>
							</AcColumn>

							<AcColumn
								xs={12}
								sm={12}
								md={6}
								className={'h-text--align-right'}
							>
								<AcButton {...this.getSubmitButtonOptions()}>
									{t('Send')}
									<AcRipple theme={THEMES.LIGHT} size={SIZES.SMALL} simple />
								</AcButton>
							</AcColumn>
						</AcRow>
					</AcContainer>
				</div>

				<AcLoader
					loading={loading.status}
					message={loading.status && loading.message}
					visual={LOADER}
					theme={THEMES.ALPHA}
					cover={true}
					coverTheme={THEMES.WHITE}
				/>
			</div>
		);
	}
}

export default withNamespaces()(AcModalContactForm);
