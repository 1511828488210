import loadable from '@loadable/component';

// Imports => Views
const AcActivate = loadable(() => import('@views/ac-activate/ac-activate.web'));
const AcAuthenticate = loadable(() =>
	import('@views/ac-authenticate/ac-authenticate.web')
);
const AcJumbo = loadable(() => import('@views/ac-jumbo/ac-jumbo.web'));
const AcMyAccount = loadable(() => import('@views/ac-my-account/ac-my-account.web'));

// Orders
const AcNewOrder = loadable(() => import('@views/ac-new-order/ac-new-order'));
const AcNewPassword = loadable(() =>
	import('@views/ac-new-password/ac-new-password')
);
const AcOrderDetail = loadable(() =>
	import('@views/ac-order-detail/ac-order-detail.web')
);
const AcOrderOverview = loadable(() =>
	import('@views/ac-order-overview/ac-order-overview.web')
);

// Transport Schedule
const AcTransportSchedule = loadable(() =>
	import('@views/ac-transport-schedule/ac-transport-schedule.web')
);

// Recurring Orders
const AcRecurringOrderOverview = loadable(() =>
	import('@views/ac-recurring-order-overview/ac-recurring-order-overview.web')
);
const AcNewRecurringOrder = loadable(() =>
	import('@views/ac-new-recurring-order/ac-new-recurring-order.web')
);

// Reports
const AcReportsOverview = loadable(() =>
	import('@views/ac-reports-overview/ac-reports-overview.web')
);
const AcReportDetail = loadable(() =>
	import('@views/ac-report-detail/ac-report-detail.web')
);

// Users
const AcUserOverview = loadable(() =>
	import('@views/ac-user-overview/ac-user-overview.web')
);

export {
	AcActivate,
	AcAuthenticate,
	AcJumbo,
	AcMyAccount,
	AcNewOrder,
	AcNewPassword,
	AcNewRecurringOrder,
	AcOrderDetail,
	AcOrderOverview,
	AcRecurringOrderOverview,
	AcReportDetail,
	AcReportsOverview,
	AcTransportSchedule,
	AcUserOverview,
};
