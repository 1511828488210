// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

// Imports => Constants
import { REDIRECT_ROUTE } from '@constants';

// Imports => Controller
import AcPrivateRouteController from './ac-private-route.controller.js';

// PrivateRoute
const PrivateRoute = ({
	authorized,
	forbidden,
	component: Component,
	...rest
}) => {
	return (
		<Route
			{...rest}
			render={props => {
				if (!forbidden || (forbidden && authorized)) {
					return <Component {...props} />;
				} else if (
					!authorized &&
					props.location.pathname !== REDIRECT_ROUTE.path &&
					window.location.pathname !== REDIRECT_ROUTE.path
				) {
					return (
						<Redirect
							from={props.location.pathname}
							to={{
								pathname: REDIRECT_ROUTE.path,
								state: { from: props.location },
							}}
						/>
					);
				} else {
					return null;
				}
			}}
		/>
	);
};

@inject('store')
@observer
class AcPrivateRoute extends AcPrivateRouteController {
	render() {
		const { store, forbidden, component, authorized, ...rest } = this.props;

		return (
			<PrivateRoute
				authorized={authorized || store.auth.is_authorized}
				forbidden={forbidden}
				component={component}
				{...rest}
			/>
		);
	}
}

export default AcPrivateRoute;
