// Imports => MOBX
import { observable, computed, action, reaction } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Config
import i18n from '@config/i18n';

// Imports => Utilities
import { AcAutoLoad, AcAutoSave, AcSaveState } from '@utils';

let app = {};

export class SettingsStore {
	constructor(store) {
		AcAutoLoad(this, KEYS.LOCALE);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	locale = 'nl';

	@observable
	languages = ['en', 'nl'];

	@observable
	loading = false;

	@computed
	get currentLanguage() {
		return this.locale;
	}

	@action
	setLoading(state) {
		this.loading = state ? state : false;
	}

	@action
	async setLanguage(locale) {
		if (!locale || locale === null) locale = 'nl';

		this.setLoading(true);

		if (app.store.auth.is_authorized) await app.store.api.locale.set(locale);

		// Set & store the locale setting
		this.set(KEYS.LOCALE, locale);

		return (this.locale = locale);
	}

	changeLanguageReaction = reaction(
		() => this.locale,
		async locale => {
			await i18n.changeLanguage(locale);
			this.setLoading(false);
		}
	);

	@action
	set(target, value, save = false) {
		if (!target) return;
		if (typeof this[target] === 'undefined') return;
		if (typeof value === 'undefined' || value === null) return;

		this[target] = value;
		if (save) AcSaveState(target, this[target]);
	}
}

export default SettingsStore;
