// Imports => Constants
import { ENDPOINTS } from '@constants';

export class ProfileAPI {
	constructor(Instance) {
		this.Client = Instance.Client;
		this.Store = Instance.Store;
	}

	whoami() {
		return this.Client.get(ENDPOINTS().PROFILE.WHOAMI).then(
			response => response.data
		);
	}

	update(_data) {
		return this.Client.put(ENDPOINTS().PROFILE.UPDATE, _data).then(
			response => response.data
		);
	}
}

export default ProfileAPI;
