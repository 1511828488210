export const POSITIONS = {
	TOP: 'top',
	RIGHT: 'right',
	BOTTOM: 'bottom',
	LEFT: 'left',
};

export const SIZES = {
	SMALL: 'small',
	DEFAULT: 'default',
	MEDIUM: 'medium',
	LARGE: 'large',
};

export const THEMES = {
	ALPHA: 'alpha',
	ALPHA_LIGHT: 'alpha_light',
	BETA: 'beta',
	DEFAULT: 'default',
	TRANSPARENT: 'transparent',
	WHITE: 'white',
	LIGHT: 'light',
	SUBTLE: 'subtle',
	MEDIUM: 'medium',
	DARK: 'dark',
	PITCH: 'pitch',
};

export const TYPES = {
	TEXT: 'text',
	TEXTAREA: 'textarea',
	PASSWORD: 'password',
	NUMBER: 'number',
	EMAIL: 'email',
	SUBMIT: 'submit',
	BUTTON: 'button',
	BACKGROUND: 'background',
	IMAGE: 'image',
};

export const VARIANTS = {
	OUTLINE: 'outline',
	DEFAULT: 'default',
	TRANSPARENT: 'transparent',
	UPPERCASE: 'uppercase',
	LOWERCASE: 'lowercase',
	SUCCESS: 'success',
	ERROR: 'error',
	WARNING: 'warning',
	INFO: 'info',
};
