import { observable, action, computed } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Models
import { PickupSealbag } from '@models/pickup-sealbag.model';
import { OrderChange } from '@models/order-change.model';
import { OrderSealbags } from '@models/order-sealbags.model';
import { OrderStamps } from '@models/order-stamps.model';

// Imports => Utilities
import {
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcClearState,
  AcFormatErrorCode,
  AcFormatErrorMessage,
} from '@utils';

const _default = {
  options: {},
  order: null,
  orders: [],
};

let app = {};

export class OrderStore {
  constructor(store) {
    AcAutoLoad(this, KEYS.OPTIONS);
    AcAutoLoad(this, KEYS.PICK_UP);
    AcAutoLoad(this, KEYS.DELIVERY);
    AcAutoSave(this);

    app.store = store;
  }

  @observable pickup = [];

  @observable delivery = [];

  @observable options = {};

  @computed
  get current_pickup_order() {
    return this.pickup;
  }

  @action
  getCurrentSealbagsInPickupOrder = id => {
    if (!this.current_pickup_order || this.current_pickup_order.length === null)
      return [];

    const collection = this.current_pickup_order;
    const length = collection.length;

    let i = 0;
    let result = [];

    for (i; i < length; i++) {
      const item = collection[i];
      if (item.id === id) continue;
      result.push(item.sealbag_number);
    }

    return result;
  };

  @observable
  loading = {
    status: false,
    message: null,
  };

  @action
  setLoading(state, message) {
    this.loading = {
      status: state || false,
      message: message || 'Opening the vault',
    };
  }

  @action
  getLocation() {
    const { location } = app.store.locations;
    if (!location) return;
    return location;
  }

  @action
  async getProducts(slot) {
    // if (
    //  !app.store.slots.slot ||
    //  !app.store.slots.slot.products ||
    //  slot.slot !== app.store.slots.slot.id
    // ) {
    await app.store.slots.get_by_id(slot.slot);
    // }
    const {
      slot: { products },
    } = app.store.slots;
    if (!products) return;
    return products;
  }

  @action
  async create() {
    this.setLoading(true);

    const { slot, type } = this.options;
    if (!slot) return;

    const location = await this.getLocation();
    if (!location) return;

    const products = await this.getProducts(slot);
    if (!products) return;

    let pickup = [];
    let delivery = [];

    if (type === KEYS.PICKUP_DELIVERY) {
      pickup = this.newPickupOrders(products[KEYS.PICK_UP]);
      delivery = this.newDeliveryOrders(products[KEYS.DELIVERY]);
    } else if (type === KEYS.PICKUP) {
      pickup = this.newPickupOrders(products[KEYS.PICK_UP]);
    } else if (type === KEYS.DELIVERY) {
      delivery = this.newDeliveryOrders(products[KEYS.DELIVERY]);
    }

    this.set(KEYS.PICK_UP, pickup);
    this.set(KEYS.DELIVERY, delivery);

    this.setLoading(false);
  }

  @action
  newPickupOrders = products => {
    let result = [];

    if (products && products[KEYS.SEALBAGS]) {
      result.push(new PickupSealbag(products[KEYS.SEALBAGS]));
    }

    return result;
  };

  @action
  newDeliveryOrders = products => {
    let result = [];

    if (products && products[KEYS.CHANGE]) {
      result.push(new OrderChange(products[KEYS.CHANGE]));
    }
    if (products && products[KEYS.SEALBAGS]) {
      result.push(new OrderSealbags(products[KEYS.SEALBAGS]));
    }
    if (products && products[KEYS.STAMPS]) {
      result.push(new OrderStamps(products[KEYS.STAMPS]));
    }

    return result;
  };

  @action
  update(type, id, name, value) {
    if (!type || !this[type]) return;
    if (!id) return;
    if (!name) return;
    if (typeof value === 'undefined' || value === null) return;

    let index = null;
    let collection = this[type].slice();

    let clen = collection.length;
    let n = 0;
    let item = null;

    for (n; n < clen; n++) {
      const _item = collection[n];

      if (_item.id === id) {
        item = _item;
        index = n;
        break;
      }
    }

    if (!item || index === null) return;

    if (typeof item[name] !== 'undefined') {
      item[name] = value;
    } else if (type === KEYS.PICK_UP && item.type === KEYS.SEALBAGS) {
      // Otherwise check if we're dealing with a Pickup => Sealbag
      // If yes, we need to look in the correct section of products [COINS, NOTES]
      let _index = null;
      let len = item[item.contents].length;
      let x = 0;

      for (x; x < len; x++) {
        let product = item[item.contents][x];

        if (product.id === +name) {
          _index = x;
          break;
        }
      }

      item[item.contents][_index].value = value;
    } else {
      let _index = null;
      let len = item.products.length;
      let x = 0;

      for (x; x < len; x++) {
        let product = item.products[x];

        if (product.id === +name) {
          _index = x;
          break;
        }
      }

      item.products[_index].value = value;
    }

    collection[index] = item;

    this.set(type, collection);
  }

  @action
  add = async (type, key) => {
    this.setLoading(true);

    const { slot } = this.options;
    if (!slot) return;

    if (!type || !key) return;
    if (type !== KEYS.PICKUP || key !== KEYS.SEALBAG) return;

    const location = await this.getLocation();
    if (!location) return;

    const products = await this.getProducts(slot);
    if (!products) return;

    let pickup = this.newPickupOrders(products[KEYS.PICK_UP]);

    if (pickup) {
      const _pickup = this.pickup.slice();
      const result = [..._pickup, ...pickup];
      this.set(KEYS.PICK_UP, result);
    }

    this.setLoading(false);
  };

  remove(type, id) {
    if (!type || !this[type]) return;
    if (!id) return;

    let collection = this[type];
    let index = null;

    let item = null;
    let len = collection.length;
    let n = 0;

    for (n; n < len; n++) {
      const _item = collection[n];

      if (_item.id === id) {
        item = _item;
        index = n;
        break;
      }
    }

    if (item && index !== null) {
      collection.splice(index, 1);

      this.set(type, collection);
    }
  }

  @action
  async resetValues(type, key, id) {
    if (!type || !this[type]) return;
    if (!key) return;
    if (!id) return;

    const { slot } = this.options;
    if (!slot) return;

    let index = null;
    let collection = this[type].slice();

    let item = null;
    let len = collection.length;
    let n = 0;

    for (n; n < len; n++) {
      const _item = collection[n];

      if (_item.id === id) {
        item = _item;
        index = n;
      }
    }

    if (!item || index === null) return;

    const location = await this.getLocation();
    if (!location) return;

    const products = await this.getProducts(slot);
    if (!products) return;

    let data = null;
    let _products = [];

    if (type === KEYS.PICK_UP) {
      _products = products[KEYS.PICK_UP];
    } else if (type === KEYS.DELIVERY) {
      _products = products[KEYS.DELIVERY];
    }

    switch (key) {
      case KEYS.SEALBAGS:
        if (type === KEYS.PICK_UP) {
          data = new PickupSealbag(_products[KEYS.SEALBAGS]);
        } else if (type === KEYS.DELIVERY) {
          data = new OrderSealbags(_products[KEYS.SEALBAGS]);
        }
        break;

      case KEYS.CHANGE:
        data = new OrderChange(_products[KEYS.CHANGE]);
        break;

      case KEYS.STAMPS:
        data = new OrderStamps(_products[KEYS.STAMPS]);
        break;

      default:
        data = null;
    }

    if (data) {
      collection[index] = {
        ...data,
        id: item.id,
        sealbag_number: item.sealbag_number,
        contents: item.contents,
      };
      this.set(KEYS.PICK_UP, collection);
    }

    this.setLoading(false);
  }

  @action
  cleanOrders(type, orders) {
    let n = 0;
    let len = orders.length;
    let result = {};

    for (n; n < len; n++) {
      const order = orders[n];

      let collection = [];
      let item = {
        id: order.id,
        type: order.type,
        products: [],
      };

      if (type === KEYS.PICKUP) {
        item.contents = order.contents;
        item.sealbag_no = order.sealbag_number;
        collection = order[order.contents];
      } else if (type === KEYS.DELIVERY) {
        collection = order.products;
      }

      let plen = collection.length;
      let x = 0;

      for (x; x < plen; x++) {
        const product = collection[x];

        if (product.value > 0)
          item.products.push({ id: product.id, quantity: product.value });
      }

      if (type === KEYS.PICKUP) {
        if (!result[order.type]) result[order.type] = [];
        if (item.products && item.products.length > 0)
          result[order.type].push(item);
      } else if (type === KEYS.DELIVERY) {
        if (!result[order.type]) result[order.type] = [];
        if (item.products && item.products.length > 0)
          result[order.type].push(item);
      }
    }

    return result;
  }

  @action
  store() {
    this.setLoading(true, 'Storing your order');

    const options = this.options;
    const pickup = this.pickup.slice();
    const delivery = this.delivery.slice();

    let data = {};

    switch (options.type) {
      case KEYS.PICKUP:
        data.pickup = this.cleanOrders(KEYS.PICKUP, pickup);
        break;

      case KEYS.DELIVERY:
        data.delivery = this.cleanOrders(KEYS.DELIVERY, delivery);
        break;

      case KEYS.PICKUP_DELIVERY:
        const _pickup = this.cleanOrders(KEYS.PICKUP, pickup);
        const _delivery = this.cleanOrders(KEYS.DELIVERY, delivery);

        data.pickup = _pickup;
        data.delivery = _delivery;
        break;

      default:
        data.pickup = [];
        data.delivery = [];
    }
    return app.store.api.orders
      .store(options.slot.slot, data)
      .then(response => {
        app.store.toasters.add({
          variant: 'success',
          title: 'Your order is stored',
        });

        this.setLoading(false);
        return response.data;
      })
      .catch(error => {
        app.store.toasters.add({
          variant: 'error',
          title: 'Failed to store your order',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });

        this.setLoading(false);
        throw error;
      });
  }

  @action
  reset(target) {
    if (!target) return;
    if (typeof this[target] === 'undefined') return;

    this[target] = _default[target];
    AcClearState(target);
  }

  @action
  set(target, value, save = true) {
    if (!target) return;
    if (typeof this[target] === 'undefined') return;
    if (typeof value === 'undefined' || value === null) return;

    this[target] = value;
    if (save) AcSaveState(target, this[target]);
  }

  @action
  setValue(target, property, value) {
    if (!target) return;
    if (typeof this[target] === 'undefined') return;
    if (!property) return;
    if (typeof value === 'undefined' || value === null) return;

    this[target][property] = value;
    AcSaveState(target, this[target]);
  }
}

export default OrderStore;
