// Imports => Vendor
import axios from 'axios';

// Imports => API
import AuthAPI from '@api/auth.api';
import AnnouncementsAPI from '@api/announcements.api';
import ContactAPI from '@api/contact.api';
import CompaniesAPI from '@api/companies.api';
import DeliverySlotsAPI from '@api/delivery-slots.api';
import LocaleAPI from '@api/locale.api';
import LocationsAPI from '@api/locations.api';
import OrdersAPI from '@api/orders.api';
import RecurringOrdersAPI from '@api/recurring-orders.api';
import ProductsAPI from '@api/products.api';
import ProfileApi from '@api/profile.api';
import ReportsAPI from '@api/reports.api';
import UsersAPI from '@api/users.api';

const onUploadProgress = event => {
	// console.group('[Axios] => fn.onUploadProgress');
	// console.log('Event: ', event);
	// console.groupEnd();
};

const onDownloadProgress = event => {
	// console.group('[Axios] => fn.onDownloadProgress');
	// console.log('Event: ', event);
	// console.groupEnd();
};

export class API {
	constructor(config, Store) {
		this.Store = Store;

		const Client = axios.create({
			...config.api,
			onUploadProgress,
			onDownloadProgress,
		});

		const DownloadClient = axios.create({
			...config.download,
			onUploadProgress,
			onDownloadProgress,
		});

		this.auth = new AuthAPI({ Store, Client });
		this.announcements = new AnnouncementsAPI({ Store, Client });
		this.contact = new ContactAPI({ Store, Client });
		this.locale = new LocaleAPI({ Store, Client });
		this.profile = new ProfileApi({ Store, Client });

		this.companies = new CompaniesAPI({ Store, Client });
		this.delivery_slots = new DeliverySlotsAPI({ Store, Client });
		this.locations = new LocationsAPI({ Store, Client });
		this.orders = new OrdersAPI({ Store, Client, DownloadClient });
		this.recurring_orders = new RecurringOrdersAPI({ Store, Client });
		this.products = new ProductsAPI({ Store, Client });
		this.reports = new ReportsAPI({ Store, Client, DownloadClient });
		this.users = new UsersAPI({ Store, Client });
	}
}

export default API;
