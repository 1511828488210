// Imports => Constants
import { ENDPOINTS } from '@constants';

export class ContactAPI {
	constructor(Instance) {
		this.Client = Instance.Client;
		this.Store = Instance.Store;
	}

	store(data) {
		return this.Client.post(ENDPOINTS().CONTACT.STORE, data).then(
			response => response.data
		);
	}
}

export default ContactAPI;
