// Imports => Constants
import { ENDPOINTS } from '@constants';

export class ProductsAPI {
	constructor(Instance) {
		this.Client = Instance.Client;
		this.Store = Instance.Store;
	}

	get_by_id = _location_id => {
		return this.Client.get(
			ENDPOINTS(this.Store.auth.current_role).PRODUCTS.GET_BY_ID(_location_id)
		).then(response => response.data);
	};

	validate_sealbag_no = data => {
		return this.Client.post(ENDPOINTS().PRODUCTS.VALIDATE_SEALBAGNO, data).then(
			response => response.data
		);
	};
}

export default ProductsAPI;
