// Imports => Constants
import { ENDPOINTS } from '@constants';

export class DeliverySlotsAPI {
	constructor(Instance) {
		this.Client = Instance.Client;
		this.Store = Instance.Store;
	}

	get_holidays = () => {
		return this.Client.get(
			ENDPOINTS(this.Store.auth.current_role).HOLIDAYS.ALL
		).then(response => response.data);
	};

	get_by_location_id = _location_id => {
		return this.Client.get(
			ENDPOINTS(this.Store.auth.current_role).DELIVERY_SLOTS.GET_BY_LOCATION_ID(
				_location_id
			)
		).then(response => response.data);
	};

	get_by_id = _id => {
		return this.Client.get(
			ENDPOINTS(this.Store.auth.current_role).DELIVERY_SLOTS.GET_BY_ID(_id)
		).then(response => response.data);
	};
}

export default DeliverySlotsAPI;
