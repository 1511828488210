// Imports => Constants
import { ENDPOINTS } from '@constants';

export class LocationsAPI {
	constructor(Instance) {
		this.Client = Instance.Client;
		this.Store = Instance.Store;
	}

	get = (params = {}) => {
		return this.Client.get(ENDPOINTS(this.Store.auth.current_role).LOCATIONS.ALL, {
			params: { options: true, ...params },
		}).then(response => response.data);
	};

	get_by_id = _location_id => {
		return this.Client.get(
			ENDPOINTS(this.Store.auth.current_role).LOCATIONS.GET_BY_ID(_location_id)
		).then(response => response.data);
	};
}

export default LocationsAPI;
