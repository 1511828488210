// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { withNamespaces } from 'react-i18next';
import { Fade } from 'react-reveal';
import clsx from 'clsx';

// Import => Constants
import { LOADING_MESSAGES } from '@constants';

const _CLASSES = {
	MAIN: 'ac-loader',
	MESSAGE: 'ac-loader__message',
	WRP: {
		MAIN: 'ac-loader-wrp',
		COVER: 'ac-loader-wrp--cover',
		DEFAULT: 'ac-loader-wrp--pitch',
		WHITE: 'ac-loader-wrp--white',
		LIGHT: 'ac-loader-wrp--light',
		OFFWHITE: 'ac-loader-wrp--offwhite',
		SUBTLE: 'ac-loader-wrp--subtle',
		MEDIUM: 'ac-loader-wrp--medium',
		DARK: 'ac-loader-wrp--dark',
		PITCH: 'ac-loader-wrp--pitch',
		ALPHA: 'ac-loader-wrp--alpha',
	},
	DEFAULT: 'ac-loader--pitch',
	WHITE: 'ac-loader--white',
	LIGHT: 'ac-loader--light',
	OFFWHITE: 'ac-loader--offwhite',
	SUBTLE: 'ac-loader--subtle',
	MEDIUM: 'ac-loader--medium',
	DARK: 'ac-loader--dark',
	PITCH: 'ac-loader--pitch',
	ALPHA: 'ac-loader--alpha',
};

let _timer = null;

// Component
const AcLoader = ({
	visual: Visual,
	message = null,
	theme,
	className,
	coverTheme,
	cover,
	wrpClassName,
	loading,
	t,
}) => {
	const [loadingMessage, setLoadingMessage] = useState(message);
	useEffect(() => {
		if (!loading && _timer) clearInterval(_timer);
		else init();
		return () => {
			if (_timer) clearInterval(_timer);
		};
	}, [loading, message]);

	const init = () => {
		if (_timer) clearInterval(_timer);

		if (loadingMessage) {
			_timer = setInterval(() => {
				setLoadingMessage(
					LOADING_MESSAGES[Math.floor(Math.random() * LOADING_MESSAGES.length)]
				);
			}, 4000);
		}
	};

	const getMessageClassNames = useMemo(() => {
		return clsx([_CLASSES.MESSAGE]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([
			_CLASSES.MAIN,
			theme && _CLASSES[theme.toUpperCase()],
			className,
		]);
	}, [theme, className]);

	const getWrpClassNames = useMemo(() => {
		return clsx([
			_CLASSES.WRP.MAIN,
			cover && _CLASSES.WRP.COVER,
			coverTheme && _CLASSES.WRP[coverTheme.toUpperCase()],
			wrpClassName,
		]);
	}, [coverTheme, cover, wrpClassName]);

	return loading && Visual ? (
		<div className={getWrpClassNames}>
			<div className={getStyleClassNames}>
				<Visual />
				{loadingMessage && (
					<Fade key={loadingMessage}>
						<span className={getMessageClassNames}>{t(loadingMessage)}</span>
					</Fade>
				)}
			</div>
		</div>
	) : null;
};

export default withNamespaces()(AcLoader);
