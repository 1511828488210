import FileSaver from 'file-saver';

export const AcDownloadFile = (data, filename, mime, bom, open = true) => {
	const blob = new Blob([data], { type: mime || 'application/octet-stream' });

	const fileURL = window.URL.createObjectURL(blob);

	return FileSaver.saveAs(blob, filename);
};

export default AcDownloadFile;
