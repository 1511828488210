// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import { AcUUID } from '@utils';

export const ORDER_TYPES = {
	COLLECT_CHANGE: 'Wisselgeld bestellen',
	PURCHASE_STAMPS: 'Jumbo Koopzegels',
	PICK_UP_COINS: 'Geld ophalen (muntgeld)',
	PICK_UP_BILLS: 'Geld ophalen (biljetten)',
};

export const ORDER_TYPES_DELIVERY = [
	ORDER_TYPES.COLLECT_CHANGE,
	ORDER_TYPES.PURCHASE_STAMPS,
];

export const ORDER_TYPES_COLLECT = [
	ORDER_TYPES.PICK_UP_COINS,
	ORDER_TYPES.PICK_UP_BILLS,
];

export const ORDER_STEPS = {
	[KEYS.PICKUP]: {
		steps: [
			{
				id: AcUUID(),
				title: 'Geld ophalen',
				type: KEYS.PICKUP,
				key: 'sealbags',
			},
		],
	},
	[KEYS.DELIVERY]: {
		steps: [
			{
				id: AcUUID(),
				title: 'Wisselgeld bestellen',
				type: KEYS.DELIVERY,
				key: 'change',
			},
			{
				id: AcUUID(),
				title: 'Sealbags bestellen',
				type: KEYS.DELIVERY,
				key: 'sealbags',
			},
			{
				id: AcUUID(),
				title: 'Koopzegels bestellen',
				type: KEYS.DELIVERY,
				key: 'stamps',
			},
		],
	},
};
