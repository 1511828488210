import { ROLES } from '@constants';

export const KEYBOARD_SHORTCUTS = [
  {
    title: 'Globaal',
    restricted: false,
    items: [
      {
        label: 'Sluit pop-up',
        key: 'Escape',
      },
    ],
  },
  {
    title: 'Data tabellen',
    restricted: false,
    items: [
      {
        label: 'Zoeken',
        key: '/',
      },
      {
        label: 'Vorige pagina',
        key: 'Pijl links',
      },
      {
        label: 'Volgende pagina',
        key: 'Pijl rechts',
      },
    ],
  },
  {
    title: 'Gebruikersoverzicht',
    restricted: [ROLES.BRANCH_MANAGER, ROLES.MANAGER, ROLES.ZIEMANN_ADMIN],
    items: [
      {
        label: 'Nieuwe gebruiker toevoegen',
        key: 'c',
      },
    ],
  },
];
